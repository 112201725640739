import React from "react";
import { motion } from "framer-motion";
import Header from "../../components/Header/Header";
import ScrollBar from "../../components/ScrollBar/ScrollBar";
import aboutLogo from "../../assets/images/about-logo.svg";
import statutte from "../../assets/images/full-statutte.png";
import GridAboutSmall from "../../components/GridAbout/GridAboutSmall";
import stickBox from "../../assets/images/border-text.svg";
import title from "../../assets/images/phygital-creative.svg";

import "./AboutSmall.css";

const ItoStudioSmall = ({ aboutRef }) => {
  return (
    <motion.div
      initial={{ x: "100vw" }}
      animate={{ x: 0 }}
      transition={{
        delay: 0.2,
        stiffness: 100,
        type: "tween",
        duration: 0.5,
      }}
      className="AboutSmall__container"
      ref={aboutRef}
      id="about"
    >
      <div className="AboutSmall__wrapper">
        <div className="AboutSmall__Header_container">
          <Header
            sectionPhoto={aboutLogo}
            phrase="ITO Laboratories is a phygital creative center dedicated to the exploration of the different realms of art and design. We
            focus primarily on bridging art between the real world and web 3."
          />
        </div>
        <div className="AboutSmall__maincontent_wrapper">
          <div className="AboutSmall__maincontent">
            <div className="AboutSmall__subtitle">
              <img src={title} alt="" />
              {/* <h3>PHYGITAL CREATIVE CENTER</h3> */}
            </div>
            <div style={{ width: "100%" }}>
              <div className="AboutSmall__box_text">
                <p>
                  In the midst of a digital revolution it is inevitable for us
                  to see the potential that blockchain technology has to help
                  potentialize any business, or brand.
                </p>
                <p>
                  ITO Lab´s vision is to help brands, artists and all types of
                  creators tap into the digital world by the imnplementation of
                  NFTs and blockchain.
                </p>
              </div>
              <div className="AboutSmall__statuttle_container">
                <div className="AboutSmall__statuttle_text">
                  <p>
                    Through our main pillars: ITO GALLERY, ITO D3SIGN and ITO
                    STUDIO we are able to offer a complete set of integral
                    services ranging from design, art production and
                    digitalization, to specialized art and NFT consulting.
                  </p>
                </div>
                <div className="AboutSmall__statuttle_image">
                  <img src={statutte} alt="" />
                </div>
              </div>
              <div className="AboutSmall__grid_container">
                <div className="AboutSmall__grid">
                  <GridAboutSmall />
                </div>
                <p>19.41658054397012, -99.16435399935689</p>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "99%" }}>
          <ScrollBar />
        </div>
      </div>
    </motion.div>
  );
};

export default ItoStudioSmall;
