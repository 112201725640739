import React, { useEffect, useRef, useState, useContext } from "react";
import { motion } from "framer-motion";
import instagramIcon from "../../assets/images/instagram.svg";
import frontAndres from "../../assets/images/front-andres.svg";
import backRodrigo from "../../assets/images/back-rodrigo.svg";
import longArrow from "../../assets/images/longArrow.svg";
import leftArrow from "../../assets/images/left-arrow.svg";
import rightArrow from "../../assets/images/right-arrow.svg";
import boxSlash from "../../assets/images/box-slash.svg";

import { useController } from "../../hooks/useController";
import { AppContext } from "../../context/Context";

import "./StudioBoxSmall.css";

const StudioBoxSmall = ({ infoHeight, boxHeight }) => {
  const { togglePortal } = useContext(AppContext);
  const rodrigoCarousel = useController();
  const andresCarousel = useController();
  const wrapper = useRef(null);
  const child_1 = useRef(null);
  const child_2 = useRef(null);
  const child_3 = useRef(null);
  const child_4 = useRef(null);
  const child_5 = useRef(null);

  const [heightWrapper, setHeightWrapper] = useState(0);
  const [childrenWidth, setChildrenWidth] = useState({
    child1: 0,
    child2: 0,
    child3: 0,
    child4: 0,
    child5: 0,
  });

  useEffect(() => {
    setHeightWrapper(wrapper.current.offsetHeight);
    setChildrenWidth({
      child1: child_1.current.offsetHeight,
      child2: child_2.current.offsetHeight,
      child3: child_3.current.offsetHeight,
      child4: child_4.current.offsetHeight,
      child5: child_5.current.offsetHeight,
    });
  }, [heightWrapper]);

  const onMouseEnter = () => {
    togglePortal(true);
  };

  const onMouseLeave = () => {
    togglePortal(false);
  };
  return (
    <div
      className="StudioBoxSmall__artists__container"
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
    >
      <div className="StudioBoxSmall__artist__wrapper">
        <div className="StudioBoxSmall__artist">
          <div className="StudioBoxSmall__artist_img">
            <span>A</span>
            <div className="StudioBoxSmall__artist_controls">
              <a onClick={(e) => rodrigoCarousel.onLeft({ e, imgLength: 12 })}>
                <img src={leftArrow} alt="" />
              </a>
              <div className="StudioBoxSmall__image_carousel">
                <img
                  src={require(`../../assets/images/rodrigo/rodrigo-${rodrigoCarousel.imageNumber}.jpeg`)}
                  alt=""
                />
              </div>
              <a onClick={(e) => rodrigoCarousel.onRight({ e, imgLength: 12 })}>
                <img src={rightArrow} alt="" />
              </a>
            </div>
          </div>
          <div className="StudioBoxSmall__artist_text">
            <p>///</p>
            <p>RODRIGO</p>
            <p>SALCEDO</p>
          </div>
          <div className="StudioBoxSmall__artist_links">
            <div className="StudioBoxSmall__artist_insta">
              <a href="https://www.instagram.com/rodrigosalcedo.v/">
                <img src={instagramIcon} alt="" />
              </a>
            </div>
            <div className="StudioBoxSmall__artist_more">
              <a href="/rodrigo">
                <p>Learn more</p>
                <img src={longArrow} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="StudioBoxSmall__artist">
          <div className="StudioBoxSmall__artist_img">
            <span>B</span>
            <div className="StudioBoxSmall__artist_controls">
              <a onClick={(e) => andresCarousel.onLeft({ e, imgLength: 10 })}>
                <img src={leftArrow} alt="" />
              </a>
              <div className="StudioBoxSmall__image_carousel">
                <img
                  src={require(`../../assets/images/andres/andres-${andresCarousel.imageNumber}.JPG`)}
                  alt=""
                />
              </div>
              <a onClick={(e) => andresCarousel.onRight({ e, imgLength: 10 })}>
                <img src={rightArrow} alt="" />
              </a>
            </div>
          </div>
          <div className="StudioBoxSmall__artist_text">
            <p>///</p>
            <p>ANDRES</p>
            <p>SALCEDO</p>
          </div>
          <div className="StudioBoxSmall__artist_links">
            <div className="StudioBoxSmall__artist_insta">
              <a href="https://www.instagram.com/andresalcedov/">
                <img src={instagramIcon} alt="" />
              </a>
            </div>
            <div className="StudioBoxSmall__artist_more">
              <a href="/andres">
                <p>Learn more</p>
                <img src={longArrow} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="StudioBoxSmall__artist">
          <div className="StudioBoxSmall__artist_img">
            <span>C</span>
            <div className="StudioBoxSmall__artist_controls">
              <a>
                <img src={leftArrow} alt="" />
              </a>
              <div className="StudioBoxSmall__image_carousel">
                <img
                  src={boxSlash}
                  style={{border: '0px'}}
                  alt=""
                />
              </div>
              <a>
                <img src={rightArrow} alt="" />
              </a>
            </div>
          </div>
          <div className="EmptyBoxSmall__artist_text">
            <p>
              NOT <wbr /> AVAILABLE <wbr />
            </p>
          </div>
          <div className="StudioBoxSmall__artist_links">
            <div className="StudioBoxSmall__artist_insta">
              <a href="https://www.instagram.com/">
                <img src={instagramIcon} alt="" />
              </a>
            </div>
            <div className="StudioBoxSmall__artist_more">
              {/* <a href="/andres">
                <p>Learn more</p>
                <img src={longArrow} alt="" />
              </a> */}
            </div>
          </div>
        </div>
        <div className="StudioBoxSmall__artist">
          <div className="StudioBoxSmall__artist_img">
            <span>D</span>
            <div className="StudioBoxSmall__artist_controls">
              <a>
                <img src={leftArrow} alt="" />
              </a>
              <div className="StudioBoxSmall__image_carousel">
                <img
                  src={boxSlash}
                  style={{border: '0px'}}
                  alt=""
                />
              </div>
              <a>
                <img src={rightArrow} alt="" />
              </a>
            </div>
          </div>
          <div className="EmptyBoxSmall__artist_text">
            <p>
              NOT <wbr /> AVAILABLE <wbr />
            </p>
          </div>
          <div className="StudioBoxSmall__artist_links">
            <div className="StudioBoxSmall__artist_insta">
              <a href="https://www.instagram.com/">
                <img src={instagramIcon} alt="" />
              </a>
            </div>
            <div className="StudioBoxSmall__artist_more">
              {/* <a href="/andres">
                <p>Learn more</p>
                <img src={longArrow} alt="" />
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className="StudioBoxSmall__artist__scroll"
        // style={{ height: `calc(${boxHeight}px - ${infoHeight}px + 5px)` }}
      >
        <div className="StudioBoxSmallScroll__vertical_container" ref={wrapper}>
          <motion.div
            ref={child_1}
            initial={{ y: 0 }}
            animate={{
              y: boxHeight - childrenWidth.child1,
            }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 7,
            }}
            className="dot__vertical"
          />
          <motion.div
            ref={child_2}
            initial={{ y: 0 }}
            animate={{ y: heightWrapper - childrenWidth.child2 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 4,
            }}
            className="dot__vertical"
          />
          <motion.div
            ref={child_3}
            initial={{ y: 0 }}
            animate={{ y: heightWrapper - childrenWidth.child3 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 6,
            }}
            className="dot__vertical"
          />
          <motion.div
            ref={child_4}
            initial={{ y: 0 }}
            animate={{ y: heightWrapper - childrenWidth.child4 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 10,
            }}
            className="dot__vertical"
          />
          <motion.div
            ref={child_5}
            initial={{ y: 0 }}
            animate={{ y: heightWrapper - childrenWidth.child5 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 8,
            }}
            className="dot__vertical"
          />
        </div>
      </div>
    </div>
  );
};

export default StudioBoxSmall;
