import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import galleryLogo from "../../assets/images/gallery-logo.svg";

import Header from "../../components/Header/Header";
import twiter from "../../assets/images/twiter.svg";
import instagram from "../../assets/images/instagram.svg";
import boat from "../../assets/images/boat.svg";
import powered from "../../assets/images/powered-border.svg";
import multiLine from "../../assets/images/multi-line.svg";
import vector from "../../assets/images/vector.svg";
import qr from "../../assets/images/qr.svg";
import Card from "../../components/Card/Card";
import isiaIcon from "../../assets/images/isia-nft.svg";
import NameSlide from "../../components/NameSlide/NameSlide";
import { RandomReveal } from "react-random-reveal";
import ScrollBarVertical from "../../components/ScrollBarVertical/ScrollBarVertical";

import "./Isia.css";

const Isia = () => {

  const wrapperText = useRef(null);
  const [isOverFlow, setIsOverFlow] = useState(false);
  const [wrapperHeight, setWrapperHeight] = useState(0);

  useEffect(() => {
    setWrapperHeight(wrapperText.current.offsetHeight);
    let curOverflow = wrapperText.current.style.overflow;

    if (!curOverflow || curOverflow === "visible")
      wrapperText.current.style.overflow = "hidden";

    let isOverflowing =
      wrapperText.current.clientWidth < wrapperText.current.scrollWidth ||
      wrapperText.current.clientHeight < wrapperText.current.scrollHeight;

    wrapperText.current.style.overflow = curOverflow;

    setIsOverFlow(isOverflowing);
  }, [wrapperText, isOverFlow]);

  console.log(isOverFlow, "isOverFlow");

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
      id="rodrigo"
    >
      {/* FIXED BARS  */}
      <div className="MainBigSize__stickyBars">
        <div className="MainBigSize__stickyBar_top_container">
          <div className="MainBigSize__stickyBar_top" />
        </div>
        {/* <div className="MainBigSize__stickyBar_bottom_container">
          <ScrollBar />
        </div> */}
      </div>
      <motion.div
        className="Isia__container"
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        transition={{
          delay: 0.2,
          stiffness: 100,
          type: "tween",
          duration: 0.5,
        }}
      >
        <div className="Isia__wrapper">
          <div className="Isia__Header__container">
            <Header
              sectionPhoto={galleryLogo}
              phrase="ITO GALLERY is our in house market
              place, (both physical and digital)
              designed to host our team and equipped to exhibit the works of our multidiciplinary artists."
            />
          </div>
          <div className="Isia__content_wrapper">
            <p>
              <span style={{ background: "black", color: "white" }}>NFT</span>{" "}
              DROPS
            </p>
            <div className="Isia__box_container">
              <div className="Isia__box_header">
                <NameSlide firtsName="/// BY ALEXANDRO GUSSO" lastName="ISIA" />
              </div>
              <div className="Isia__box_wrapper_content">
                <div className="Isia__content_left">
                  <p className="Isia_float_numbers">00000 01</p>

                  <div className="Isia__card_container">
                    <Card
                      image={isiaIcon}
                      smallPhrase={`"ISIA" #0000018283`}
                      autor="Created by Alexandro Gusso"
                      middlePhrase="sold for 0.35ETH"
                      // imageInfo={false}
                      phrase="Feeling is the representation of various sentiments in a minimalist way, taking artistic elements and geometric elements to represent these in a larger spectrum of how we sense these feelings."
                    />
                    <div
                      style={{
                        position: "relative",
                        width: "5%",
                        height: "98%",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <p className="Isia_float_code">
                        {
                          '{"internalType":"Kosmovisiones","name":"collectionSize2222","type":soul"uint1"}'
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <div className="Isia__content_right">
                  <div className="Isia__content_right_content">
                    <div className="Isia__content_right_content_text" ref={wrapperText}>
                      <RandomReveal
                        isPlaying
                        duration={4}
                        characterSet="lang:=”en” data-light>”DARK”</head>"
                        revealEasing="linear"
                        characters={`ISIA is part of “ F3lings” a collection of 3 1/1 fine art pieces  representing the 3 most important feelings in a human, 
                        where with a minimalist animation we achieve transport you into different feelings.`}
                      />
                      <br />
                      <br />
                      <RandomReveal
                        isPlaying
                        duration={4}
                        characterSet="lang:=”en” data-light>”DARK”</head>"
                        revealEasing="linear"
                        characters={`For Alexandro Gusso the minimalism and the environment creation takes a great part in his creative side of his NFT’s. 
                        where he tries to always implement a good lighting setup and a original and surreal environment. Looking for new implementations and ways to introduce an original way of creating 3D work Alexandro tries the best and new technologies to create the best result.`}
                      />
                      <br />
                      <br />
                      <RandomReveal
                        isPlaying
                        duration={4}
                        characterSet="lang:=”en” data-light>”DARK”</head>"
                        revealEasing="linear"
                        characters={`Lead 3D artist of the collection “Antonym” a collection of 8888 NFTs  where he learn all the implications of 
                        creating a 3D collection with fine details and a creative direction with a physical and digital asset in game.`}
                      />
                    </div>
                    <div
                      style={{
                        height: "95%",
                        width: "auto",
                        marginLeft: "1em",
                        // position: 'absolute',
                        // top: 0,
                        // bottom: 0,
                        // right: '-10px',
                        // zIndex: 3
                        // left: 0
                      }}
                    >
                      {isOverFlow && (
                        <ScrollBarVertical wrapperHeight={wrapperHeight} />
                      )}
                    </div>
                  </div>
                  <div className="Isia__content_right_bottom">
                    <div className="Isia__content_img">
                      <img src={twiter} alt="" />
                    </div>
                    <div className="Isia__content_img">
                      <img src={instagram} alt="" />
                    </div>
                    <div className="Isia__content_img">
                      <img src={boat} alt="" />
                    </div>
                    <div className="Isia__content_img">
                      <img src={powered} alt="" />
                    </div>
                    <div className="Isia__content_img">
                      <img src={multiLine} alt="" />
                    </div>
                    <div className="Isia__content_img">
                      <img src={vector} alt="" />
                    </div>
                    <div className="Isia__content_img">
                      <img src={qr} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Isia;
