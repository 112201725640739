import { useState } from "react";

export const useController = () => {
  const [imageNumber, setImageNumber] = useState(0);

  const onLeft = ({ e, imgLength }) => {
    e?.preventDefault();

    if (imageNumber <= 0) {
      setImageNumber(imgLength);
    } else {
      setImageNumber(imageNumber - 1);
    }
  };

  const onRight = ({ e, imgLength }) => {
    e?.preventDefault();

    if (imageNumber >= imgLength) {
      setImageNumber(0);
    } else {
      setImageNumber(imageNumber + 1);
    }
  };

  return {
    imageNumber,
    onLeft,
    onRight,
  };
};
