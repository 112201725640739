import React, { useState, useEffect, useRef, useContext } from "react";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import LetsTalkSmall from "../LetsTalkSmall";
import ItoStudioSmall from "../ItoStudioSmall/ItoStudioSmall";
import ItoGallerySmall from "../ItoGallerySmall";
import DesignSmall from "../DesignSmall";
import AboutSmall from "../AboutSmall";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import { useInView } from "react-intersection-observer";
import { AppContext } from "../../context/Context";
import { useLocation } from "react-router-dom";

import "./MainSmallSize.css";

const MainSmallSize = () => {
  const {
    setIsGallery,
    setIsStudio,
    setIsTalk,
    setIsDesign,
    setIsAbout,
    setParallaxRef,
  } = useContext(AppContext);
  const location = useLocation();
  const parallax = useRef(null);

  const letsTalk = useInView({ threshold: 0.1 });
  const itoStudio = useInView({ threshold: 0.1 });
  const itoGallery = useInView({ threshold: 0.1 });
  const itoDesign = useInView({ threshold: 0.1 });
  const aboutUs = useInView({ threshold: 0.1 });

  useEffect(() => {
    setParallaxRef(parallax);
    if (location.hash == "#about") parallax.current.scrollTo(0);
    if (location.hash == "#design") parallax.current.scrollTo(1);
    if (location.hash == "#gallery") parallax.current.scrollTo(2);
    if (location.hash == "#studio") parallax.current.scrollTo(3);
    if (location.hash == "#talk") parallax.current.scrollTo(4);
  }, [location]);

  if (letsTalk.inView) {
    setIsTalk(true);
  } else {
    setIsTalk(false);
  }

  if (itoStudio.inView) {
    setIsStudio(true);
  } else {
    setIsStudio(false);
  }

  if (itoGallery.inView) {
    setIsGallery(true);
  } else {
    setIsGallery(false);
  }

  if (itoDesign.inView) {
    setIsDesign(true);
  } else {
    setIsDesign(false);
  }

  if (aboutUs.inView) {
    setIsAbout(true);
  } else {
    setIsAbout(false);
  }

  const handleSwipeLeft = (e) => {
    console.log("right");

    if (aboutUs.inView) parallax.current.scrollTo(1);

    if (itoDesign.inView) parallax.current.scrollTo(2);

    if (itoGallery.inView) parallax.current.scrollTo(3);

    if (itoStudio.inView) parallax.current.scrollTo(4);

    if (letsTalk.inView) parallax.current.scrollTo(5);

    console.log(letsTalk.inView);
  };
  const handleSwipeRight = (e) => {
    console.log("left");

    if (aboutUs.inView) window.location.href = "/";

    if (itoDesign.inView) parallax.current.scrollTo(0);

    if (itoGallery.inView) parallax.current.scrollTo(1);

    if (itoStudio.inView) parallax.current.scrollTo(2);

    if (letsTalk.inView) parallax.current.scrollTo(3);
  };

  return (
    <div
      className=""
      style={{
        height: "100%",
        overflow: "hidden",
        touchAction: "none",
        msTouchAction: "none",
      }}
    >
      <ReactScrollWheelHandler
        rightHandler={(e) => handleSwipeRight(e)}
        leftHandler={(e) => handleSwipeLeft(e)}
        timeout={1000}
        style={{
          overflow: "hidden",
          touchAction: "none",
          msTouchAction: "none",
        }}
      >
        {/* FIXED BARS  */}
        <div className="MainSmallSize__stickyBars">
          <div className="MainSmallSize__stickyBar_top_container">
            <div className="MainSmallSize__stickyBar_top" />
          </div>
        </div>

        <Parallax
          pages={5}
          horizontal
          style={{
            height: `calc(${window.innerHeight}px - 10px)`,
            width: "100%",
            overflow: "hidden",
            touchAction: "none",
            msTouchAction: "none",
          }}
          ref={parallax}
        >
          <ParallaxLayer
            offset={0}
            style={{
              display: "flex",
              justifyContent: "center",
              overflow: "hidden",
              touchAction: "none",
              msTouchAction: "none",
            }}
          >
            <AboutSmall aboutRef={aboutUs.ref} />
          </ParallaxLayer>

          <ParallaxLayer
            offset={1}
            style={{
              display: "flex",
              justifyContent: "center",
              overflow: "hidden",
              touchAction: "none",
              msTouchAction: "none",
            }}
          >
            <DesignSmall designRef={itoDesign.ref} />
          </ParallaxLayer>

          <ParallaxLayer
            offset={2}
            style={{
              display: "flex",
              justifyContent: "center",
              overflow: "hidden",
              touchAction: "none",
              msTouchAction: "none",
            }}
          >
            <ItoGallerySmall galleryRef={itoGallery.ref} />
          </ParallaxLayer>

          <ParallaxLayer
            offset={3}
            style={{
              display: "flex",
              justifyContent: "center",
              overflow: "hidden",
              touchAction: "none",
              msTouchAction: "none",
            }}
          >
            <ItoStudioSmall studioRef={itoStudio.ref} />
          </ParallaxLayer>

          <ParallaxLayer
            offset={4}
            style={{
              display: "flex",
              justifyContent: "center",
              overflow: "hidden",
              touchAction: "none",
              msTouchAction: "none",
            }}
          >
            <LetsTalkSmall talkRef={letsTalk.ref} />
          </ParallaxLayer>
        </Parallax>
      </ReactScrollWheelHandler>
    </div>
  );
};

export default MainSmallSize;
