import React, { useState, useEffect, useContext, useRef } from "react";
import HomeSmall from "./HomeSmall";
import HomeSmall_2 from "./HomeSmall_2";
import HomeSmall_3 from "./HomeSmall_3";
import HomeSmall_4 from "./HomeSmall_4";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import { useInView } from "react-intersection-observer";
import GlitchTitle from "../../components/Glitch/GlitchTitle";
import InfoDate from "../../components/InfoDate";
import { AppContext } from "../../context/Context";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";

import "./index.css";

const Home = () => {
  const parallax = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const home1 = useInView({ threshold: 0.1 });
  const home2 = useInView({ threshold: 0.1 });
  const home3 = useInView({ threshold: 0.1 });
  const home4 = useInView({ threshold: 0.1 });

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleSwipeRight = () => {
    console.log("left");

    if (home1.inView) parallax.current.scrollTo(0);

    if (home2.inView) parallax.current.scrollTo(0);

    if (home3.inView) parallax.current.scrollTo(1);

    if (home4.inView) parallax.current.scrollTo(2);
  };

  const handleSwipeLeft = () => {
    console.log("right");

    if (home1.inView) parallax.current.scrollTo(1);

    if (home2.inView) parallax.current.scrollTo(2);

    if (home3.inView) parallax.current.scrollTo(3);

    if (home4.inView) {
      parallax.current.scrollTo(4);
      window.location.href = "/main";
    }
  };

  console.log(home1.inView);

  return (
    <div className="">
      <ReactScrollWheelHandler
        rightHandler={(e) => handleSwipeRight(e)}
        leftHandler={(e) => handleSwipeLeft(e)}
        upHandler={(e) => handleSwipeRight(e)}
        downHandler={(e) => handleSwipeLeft(e)}
        timeout={700}
      >
        <Parallax
          pages={4}
          horizontal
          style={{ height: "100vh", overflow: "hidden" }}
          ref={parallax}
        >
          <ParallaxLayer
            offset={0}
            speed={0}
            style={{
              display: "flex",
              justifyContent: "center",
              overflow: "hidden",
              alignItems: "center",
            }}
          >
            <HomeSmall firtsRef={home1.ref} />
          </ParallaxLayer>

          <ParallaxLayer
            sticky={{ start: 0, end: 3 }}
            style={
              windowWidth > 500
                ? {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "9em",
                  }
                : {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "6em",
                  }
            }
          >
            <GlitchTitle />
            <InfoDate isCode />
          </ParallaxLayer>

          <ParallaxLayer
            offset={1}
            speed={0}
            style={{
              display: "flex",
              justifyContent: "center",
              overflow: "hidden",
              alignItems: "center",
            }}
          >
            <HomeSmall_2 secondRef={home2.ref} />
          </ParallaxLayer>

          <ParallaxLayer
            offset={2}
            speed={0}
            style={{
              display: "flex",
              justifyContent: "center",
              overflow: "hidden",
              alignItems: "center",
            }}
          >
            <HomeSmall_3 thirdRef={home3.ref} />
          </ParallaxLayer>

          <ParallaxLayer
            offset={3}
            speed={0}
            style={{
              display: "flex",
              justifyContent: "center",
              overflow: "hidden",
              alignItems: "center",
            }}
          >
            <HomeSmall_4 fourthRef={home4.ref} />
          </ParallaxLayer>
        </Parallax>
      </ReactScrollWheelHandler>
    </div>
  );
};

export default Home;
