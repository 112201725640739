import React, { useState } from "react";
import { motion } from "framer-motion";
import Header from "../../components/Header/Header";
import MineSweeper from "../../components/MineSweeper/MineSweeper";
import CustomForm from "../../components/CustomForm/CustomForm";

import "./LetsTalk.css";
const LetsTalk = () => {
  const [isFilter, setIsFilter] = useState(true);

  const handleShowFilter = () => {
    setIsFilter(false);
  };

  return (
    <motion.div
      className="Talk__container"
      initial={{ x: "100vw" }}
      animate={{ x: 0 }}
      transition={{
        delay: 0.2,
        stiffness: 100,
        type: "tween",
        duration: 0.5,
      }}
    >
      <div className="Talk__wrapper">
        <div className="Talk__header_container">
          <Header
            phrase="We can´t wait to hear to hear from you, dont forget to submit your enquiry 
            the metaverse is waiting for you..."
          />
        </div>
        <div className="Talk__content_wrapper">
          <div className="Talk__content_container">
            <div className="Talk__left_container">
              <div className="Talk__floatNumbers">
                <p>01001001 01010100 01001111 00100000</p>
              </div>
              <div className="Talk__form">
                <CustomForm handleShowFilter={handleShowFilter} isInfoDate />
              </div>
            </div>
            <div className="Talk__right_container">
              <div className="Talk__right_wrapper">
                <MineSweeper isFilter={isFilter} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default LetsTalk;
