import React from "react";
import ImageHover from "../../components/ImageHover/ImageHover";
import "./HomeSmall.css";

const HomeSmall = ({ firtsRef }) => {
  return (
    <div ref={firtsRef}>
      {/* LOGO */}
      <div className="homeSmall__wrapper">
        <div className="homeSmall__logo_wrap">
          <div id="title">
            <ImageHover />
          </div>
          <h2 className="homeSmall__logo_text">PHYGITAL LABORATORIES</h2>
        </div>
      </div>
    </div>
  );
};

export default HomeSmall;
