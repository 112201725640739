import React, { useContext, useState } from "react";
import longArrow from "../../assets/images/longArrow.svg";
import desingIcon from "../../assets/images/3-ito-design.svg";
import folderIcon from "../../assets/images/folder.svg";
import studioIcon from "../../assets/images/tatto-studio.svg";
import galleryIcon from "../../assets/images/frame-gallery.svg";
import { AppContext } from "../../context/Context";
import "./GridAboutSmall.css";

const GridAboutSmall = () => {
  const { parallax } = useContext(AppContext);

  const [isDesignHover, setIsDesignHover] = useState(false);
  const [isStudioHover, setIsStudioHover] = useState(false);
  const [isGalleryHover, setIsGalleryHover] = useState(false);

  return (
    <div className="GridAboutSmall__container">
      {/* LEFT SIDE */}
      <div className="GridAboutSmall__left_container">
        <div
          className={
            isDesignHover ? "GridAbout__item_dark" : "GridAboutSmall__item"
          }
          onMouseEnter={() => setIsDesignHover(true)}
          onMouseLeave={() => setIsDesignHover(false)}
        >
          <div
            className={
              isDesignHover
                ? "GridAboutSmall__subitem_top_dark"
                : "GridAboutSmall__subitem_top"
            }
          >
            <img src={desingIcon} alt="" />
          </div>
          <div className="GridAboutSmall__subitem_bottom">
            <div
              className={
                isDesignHover
                  ? "GridAboutSmall__subitem_bottom_left_dark"
                  : "GridAboutSmall__subitem_bottom_left"
              }
            >
              <img src={folderIcon} alt="" />
            </div>
            <div
              className={
                isDesignHover
                  ? "GridAboutSmall__subitem_bottom_right_dark"
                  : "GridAboutSmall__subitem_bottom_right"
              }
            >
              <p>デザインスタジオ</p>
            </div>
          </div>
        </div>
        <div
          className={
            isStudioHover ? "GridAbout__item_dark" : "GridAboutSmall__item"
          }
          onMouseEnter={() => setIsStudioHover(true)}
          onMouseLeave={() => setIsStudioHover(false)}
        >
          <div
            className={
              isStudioHover
                ? "GridAboutSmall__subitem_top_dark"
                : "GridAboutSmall__subitem_top"
            }
          >
            <img src={studioIcon} alt="" />
          </div>
          <div className="GridAboutSmall__subitem_bottom">
            <div
              className={
                isStudioHover
                  ? "GridAboutSmall__subitem_bottom_left_dark"
                  : "GridAboutSmall__subitem_bottom_left"
              }
            >
              <img src={folderIcon} alt="" />
            </div>
            <div
              className={
                isStudioHover
                  ? "GridAboutSmall__subitem_bottom_right_dark"
                  : "GridAboutSmall__subitem_bottom_right"
              }
            >
              <p>タトゥースタジオ</p>
            </div>
          </div>
        </div>
        <div
          className={
            isGalleryHover ? "GridAbout__item_dark" : "GridAboutSmall__item"
          }
          onMouseEnter={() => setIsGalleryHover(true)}
          onMouseLeave={() => setIsGalleryHover(false)}
        >
          <div
            className={
              isGalleryHover
                ? "GridAboutSmall__subitem_top_dark"
                : "GridAboutSmall__subitem_top"
            }
          >
            <img src={galleryIcon} alt="" />
          </div>
          <div className="GridAboutSmall__subitem_bottom">
            <div
              className={
                isGalleryHover
                  ? "GridAboutSmall__subitem_bottom_left_dark"
                  : "GridAboutSmall__subitem_bottom_left"
              }
            >
              <img src={folderIcon} alt="" />
            </div>
            <div
              className={
                isGalleryHover
                  ? "GridAboutSmall__subitem_bottom_right_dark"
                  : "GridAboutSmall__subitem_bottom_right"
              }
            >
              <p>アートギャラリー</p>
            </div>
          </div>
        </div>
      </div>

      {/* RIGHT SIDE */}
      <div className="GridAboutSmall__right_container">
        <div
          className={
            isDesignHover ? "GridAbout__item_dark" : "GridAboutSmall__item"
          }
          onMouseEnter={() => setIsDesignHover(true)}
          onMouseLeave={() => setIsDesignHover(false)}
        >
          <div
            className={
              isDesignHover
                ? "GridAboutSmall__item_text_dark"
                : "GridAboutSmall__item_text"
            }
          >
            <p>
              <span>I</span>s a collective of design, and cryptoart dedicated to
              the development of 3D and various digital assets.
            </p>
          </div>
          <div
            className={
              isDesignHover
                ? "GridAboutSmall__item_link_dark"
                : "GridAboutSmall__item_link"
            }
          >
            <a onClick={() => parallax.current.scrollTo(1)}>Learn more</a>
            <img src={longArrow} alt="" />
          </div>
        </div>
        <div
          className={
            isStudioHover ? "GridAbout__item_dark" : "GridAboutSmall__item"
          }
          onMouseEnter={() => setIsStudioHover(true)}
          onMouseLeave={() => setIsStudioHover(false)}
        >
          <div
            className={
              isStudioHover
                ? "GridAboutSmall__item_text_dark"
                : "GridAboutSmall__item_text"
            }
          >
            <p>
              <span>A</span>n inhouse contemporary tattoo studio, a place where
              the physical world collides with the digital one.
            </p>
          </div>
          <div
            className={
              isStudioHover
                ? "GridAboutSmall__item_link_dark"
                : "GridAboutSmall__item_link"
            }
          >
            <a onClick={() => parallax.current.scrollTo(3)}>Learn more</a>
            <img src={longArrow} alt="" />
          </div>
        </div>
        <div
          className={
            isGalleryHover ? "GridAbout__item_dark" : "GridAboutSmall__item"
          }
          onMouseEnter={() => setIsGalleryHover(true)}
          onMouseLeave={() => setIsGalleryHover(false)}
        >
          <div
            className={
              isGalleryHover
                ? "GridAboutSmall__item_text_dark"
                : "GridAboutSmall__item_text"
            }
          >
            <p>
              <span>T</span>his is our in house market place, equipped to
              exhibit our own individual work, and the work of other digital
              artists.
            </p>
          </div>
          <div
            className={
              isGalleryHover
                ? "GridAboutSmall__item_link_dark"
                : "GridAboutSmall__item_link"
            }
          >
            <a onClick={() => parallax.current.scrollTo(2)}>Learn more</a>
            <img src={longArrow} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridAboutSmall;
