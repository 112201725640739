import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import Header from "../../components/Header/Header";
import GalleryBox from "../../components/GalleryBox/GalleryBox";
import EmptyBox from "../../components/GalleryBox/EmptyBox";
import Infodate from "../../components/InfoDate";
import Location from "../../components/Location/Location";
import galleryLogo from "../../assets/images/gallery-logo.svg";
import { Portal } from "react-portal";
import stickBox from "../../assets/images/border-text.svg";
import title from "../../assets/images/phygial-gallery.svg";

import "./ItoGallery.css";

const ItoGallery = () => {
  const info = useRef(null);
  const wrapperBox = useRef(null);
  const [boxWidth, setBoxWidth] = useState(0);
  const [boxHeight, setBoxHieght] = useState(0);

  const [infoHeight, setInfoHeight] = useState(0);

  const [isGuest, setIsGuest] = useState(false);

  useEffect(() => {
    const boxWidth = wrapperBox.current.offsetWidth;
    const boxHeight = wrapperBox.current.offsetHeight;

    const infoHeight = info.current.offsetHeight;
    setInfoHeight(infoHeight);
    setBoxWidth(boxWidth);
    setBoxHieght(boxHeight);
  }, []);

  return (
    <motion.div
      className="Gallery__container"
      initial={{ x: "100vw" }}
      animate={{ x: 0 }}
      transition={{
        delay: 0.2,
        stiffness: 100,
        type: "tween",
        duration: 0.5,
      }}
    >
      <div className="Gallery__wrapper">
        <div className="Gallery__Header__container">
          <Header
            sectionPhoto={galleryLogo}
            phrase="Designed to exhibit the work of our artists, hosting experiences and events. Both physically and digitally."
          />
        </div>
        <div className="Gallery__content_wrapper">
          <div className="Gallery__left__container">
            <div className="Gallery__subtitle__container">
              <img src={title} alt="" />
            </div>
            <div className="Gallery__text__container">
              <div className="Gallery__text__left">
                <p>
                  This phygital center is essential for the armonic co-existance
                  of both the digital and the real world, it helps us maximize
                  our creative potential.
                </p>
                <p>
                  The gallery hosts inmersive art experiences and cultural
                  events, a real phygital house located in heart of Mexico City
                  and in the center of web 3.
                </p>
              </div>
              <div style={{ width: "100%" }}>
                <Location />
              </div>
            </div>
          </div>
          <div className="Gallery__right__container">
            <div className="Gallery__subtitle__container2">
              <p>
                <span className="Gallery__subtitle__box">
                  {!isGuest ? "NFT" : "EVENTS"}
                </span>{" "}
                <span style={{ letterSpacing: "5px" }}>
                  {!isGuest ? "DROPS" : ""}
                </span>
                <a onClick={() => setIsGuest(!isGuest)}>
                  <span className="Gallery__subtitle__anchor">
                    {!isGuest ? " ///EVENTS" : " ///NFT DROPS"}
                  </span>
                </a>
              </p>
            </div>
            <div ref={wrapperBox} className="Gallery__portal__container">
              {!isGuest ? (
                <div className="Gallery__portal__wrapper">
                  <GalleryBox infoHeight={infoHeight} boxHeight={boxHeight} />
                  <div ref={info}>
                    <Infodate />
                  </div>
                </div>
              ) : (
                <div className="Gallery__portal__wrapper">
                  <EmptyBox
                    infoHeight={infoHeight}
                    boxHeight={boxHeight}
                    prhase="NO EVENTS IN THE CALENDAR"
                  />

                  <div style={{ width: boxWidth }} ref={info}>
                    <Infodate />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ItoGallery;
