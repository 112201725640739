import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import ScrollBarVertical from "../../components/ScrollBarVertical/ScrollBarVertical";
import Header from "../../components/Header/Header";
import twiter from "../../assets/images/twiter.svg";
import instagram from "../../assets/images/instagram.svg";
import boat from "../../assets/images/boat.svg";
import powered from "../../assets/images/powered-border.svg";

import multiLine from "../../assets/images/multi-line.svg";
import vector from "../../assets/images/vector.svg";
import qr from "../../assets/images/qr.svg";
import studioLogo from "../../assets/images/studio-logo.svg";
import Card from "../../components/Card/Card";
import andresIcon from "../../assets/images/andres-card.JPG";
import NameSlide from "../../components/NameSlide/NameSlide";
import { RandomReveal } from "react-random-reveal";

const Andres = () => {
  const wrapperText = useRef(null);
  const [isOverFlow, setIsOverFlow] = useState(false);
  const [wrapperHeight, setWrapperHeight] = useState(0);

  useEffect(() => {
    setWrapperHeight(wrapperText.current.offsetHeight);
    let curOverflow = wrapperText.current.style.overflow;

    if (!curOverflow || curOverflow === "visible")
      wrapperText.current.style.overflow = "hidden";

    let isOverflowing =
      wrapperText.current.clientWidth < wrapperText.current.scrollWidth ||
      wrapperText.current.clientHeight < wrapperText.current.scrollHeight;

    wrapperText.current.style.overflow = curOverflow;

    setIsOverFlow(isOverflowing);
  }, [wrapperText, isOverFlow]);

  console.log(isOverFlow, "isOverFlow");
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
      id="rodrigo"
    >
      {/* FIXED BARS  */}
      <div className="MainBigSize__stickyBars">
        <div className="MainBigSize__stickyBar_top_container">
          <div className="MainBigSize__stickyBar_top" />
        </div>
        {/* <div className="MainBigSize__stickyBar_bottom_container">
          <ScrollBar />
        </div> */}
      </div>
      <motion.div
        className="Artist__container"
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        transition={{
          delay: 0.2,
          stiffness: 100,
          type: "tween",
          duration: 0.5,
        }}
      >
        <div className="Artist__wrapper">
          <div className="Artist__Header__container">
            <Header
              sectionPhoto={studioLogo}
              phrase="ITO STUDIO is an inhouse contemporary tattoo studio, we implemented blockchain
              technology into tattooing, a place
              where the physical world collides with the digital one and manages to coexist
              in perfect harmony.
               "
            />
          </div>
          <div className="Artist__content_wrapper">
            <p>RESIDENT ARTISTS</p>
            <div className="Artist__box_container">
              <div className="Artist__box_header">
                <NameSlide firtsName="/// ANDRES" lastName="SALCEDO" />
              </div>
              <div className="Artist__box_wrapper_content">
                <div className="Artist__content_left">
                  <p className="Artist_float_numbers">00000 01</p>

                  <div className="Artist__card_container">
                    <Card
                      image={andresIcon}
                      smallPhrase={`“Tatooing is more than lines it is art”  
                      Andres Salcedo `}
                      autor="Created by Alexandro Gusso"
                      middlePhrase="[Loc.*] 40.7548ºN, 73.9774ºW"
                      imageInfo={false}
                      phrase="The portals are the graphic representation of an awakening moment, they are the point of no return, an interdimensional passage through which one can bridge the regular state of mind with altered states of conciousness"
                    />
                    <div
                      style={{
                        position: "relative",
                        width: "5%",
                        height: "98%",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <p
                        className="Artist_float_code"
                        style={{ whiteSpace: "nowrap", letterSpacing: "1.4px" }}
                      >
                        {
                          '{{"internalType":"Rodrigo salcedo",":"collectionSize2222","type":soul"uint1"}}'
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <div className="Artist__content_right">
                  <div className="Artist__content_right_content">
                    <div
                      className="Artist__content_right_content_text"
                      ref={wrapperText}
                    >
                      <RandomReveal
                        isPlaying
                        duration={4}
                        characterSet="lang:=”en” data-light>”DARK”</head>"
                        revealEasing="linear"
                        characters={`Andres Salcedo Velarca is an artist born in Guadalajara City, Mexico. 
                        Since a young age he has been surrounded by art, inspired by it, he decided to study Architecture for over two years. After exploring his potential in the art world he took the decision to drop out of college and try new ways to reach and inspire society at large. Starting in the world of contemporary tattoo, in a little over a year he has gained wide recognition allowing him to guest at the top tier studios around the world.
                        After participating in his first art show last November, he has continued to explore illustration and painting,  in addition to constantly perfecting his tattooing technique. 
                        He currently resides in Mexico City and is part of itolab.`}
                      />
                    </div>
                    <div
                      style={{
                        height: "95%",
                        width: "auto",
                        marginLeft: "1em",
                      }}
                    >
                      {isOverFlow && (
                        <ScrollBarVertical wrapperHeight={wrapperHeight} />
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      height: "50%",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="Artist__content_right_content_button"
                      style={{ marginTop: "0.5em", marginBottom: "2em" }}
                    >
                      <a href="/appointment">appointments</a>
                    </div>
                    <div className="Artist__content_right_bottom">
                      <div className="Artist__content_img">
                        <img src={twiter} alt="" />
                      </div>
                      <div className="Artist__content_img">
                        <img src={instagram} alt="" />
                      </div>
                      <div className="Artist__content_img">
                        <img src={boat} alt="" />
                      </div>
                      <div className="Artist__content_img">
                        <img src={powered} alt="" />
                      </div>
                      <div className="Artist__content_img">
                        <img src={multiLine} alt="" />
                      </div>
                      <div className="Artist__content_img">
                        <img src={vector} alt="" />
                      </div>
                      <div className="Artist__content_img">
                        <img src={qr} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Andres;
