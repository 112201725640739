import React, { useEffect, useState } from "react";
import MineSweeperGame from "pb-minesweeper";
import ScoreBoard from "../ScoreBoard/ScoreBoard";
import FilterLock from "../FilterLock/FilterLock";
import "./MineSweeper.css";

const MineSweeper = ({ isFilter }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  return (
    <div className="MineSweeper__wrapper">
      <div className="MineSweeper__container">
        {isFilter && <FilterLock />}
        {/* DESKTOP VIEW */}

        {windowWidth <= 950 && windowWidth >= 931 ? (
          <MineSweeperGame mines={99} gridSize={[45, 19]} />
        ) : null}
        {windowWidth <= 930 && windowWidth >= 911 ? (
          <MineSweeperGame mines={99} gridSize={[44, 25]} />
        ) : null}
        {windowWidth <= 910 && windowWidth >= 891 ? (
          <MineSweeperGame mines={99} gridSize={[43, 25]} />
        ) : null}
        {windowWidth <= 890 && windowWidth >= 871 ? (
          <MineSweeperGame mines={99} gridSize={[42, 25]} />
        ) : null}
        {windowWidth <= 870 && windowWidth >= 851 ? (
          <MineSweeperGame mines={99} gridSize={[41, 25]} />
        ) : null}
        {windowWidth <= 850 && windowWidth >= 831 ? (
          <MineSweeperGame mines={99} gridSize={[40, 25]} />
        ) : null}
        {windowWidth <= 830 && windowWidth >= 811 ? (
          <MineSweeperGame mines={99} gridSize={[39, 25]} />
        ) : null}
        {windowWidth <= 810 && windowWidth >= 791 ? (
          <MineSweeperGame mines={99} gridSize={[38, 25]} />
        ) : null}
        {windowWidth <= 790 && windowWidth >= 771 ? (
          <MineSweeperGame mines={99} gridSize={[37, 25]} />
        ) : null}
        {windowWidth <= 770 && windowWidth >= 751 ? (
          <MineSweeperGame mines={99} gridSize={[36, 25]} />
        ) : null}
        {windowWidth <= 750 && windowWidth >= 731 ? (
          <MineSweeperGame mines={99} gridSize={[35, 25]} />
        ) : null}
        {windowWidth <= 730 && windowWidth >= 711 ? (
          <MineSweeperGame mines={99} gridSize={[34, 25]} />
        ) : null}
        {windowWidth <= 710 && windowWidth >= 691 ? (
          <MineSweeperGame mines={99} gridSize={[33, 25]} />
        ) : null}
        {windowWidth <= 690 && windowWidth >= 671 ? (
          <MineSweeperGame mines={99} gridSize={[32, 25]} />
        ) : null}
        {windowWidth <= 670 && windowWidth >= 651 ? (
          <MineSweeperGame mines={99} gridSize={[31, 25]} />
        ) : null}
        {windowWidth <= 650 && windowWidth >= 631 ? (
          <MineSweeperGame mines={99} gridSize={[30, 24]} />
        ) : null}
        {windowWidth <= 630 && windowWidth >= 611 ? (
          <MineSweeperGame mines={99} gridSize={[29, 24]} />
        ) : null}
        {windowWidth <= 610 && windowWidth >= 581 ? (
          <MineSweeperGame mines={99} gridSize={[28, 21]} />
        ) : null}
        {windowWidth <= 580 && windowWidth >= 561 ? (
          <MineSweeperGame mines={99} gridSize={[27, 19]} />
        ) : null}

        {windowWidth <= 560 && windowWidth >= 541 ? (
          <MineSweeperGame mines={99} gridSize={[26, 19]} />
        ) : null}
        {windowWidth <= 540 && windowWidth >= 521 ? (
          <MineSweeperGame mines={99} gridSize={[25, 19]} />
        ) : null}
        {windowWidth <= 520 && windowWidth >= 501 ? (
          <MineSweeperGame mines={99} gridSize={[24, 19]} />
        ) : null}

        {windowWidth <= 500 && windowWidth >= 481 ? (
          <MineSweeperGame mines={99} gridSize={[23, 19]} />
        ) : null}

        {windowWidth <= 480 && windowWidth >= 461 ? (
          <MineSweeperGame mines={99} gridSize={[22, 19]} />
        ) : null}

        {windowWidth <= 460 && windowWidth >= 441 ? (
          <MineSweeperGame mines={99} gridSize={[21, 19]} />
        ) : null}

        {windowWidth <= 440 && windowWidth >= 421 ? (
          <MineSweeperGame mines={99} gridSize={[20, 19]} />
        ) : null}

        {windowWidth <= 420 && windowWidth >= 401 ? (
          <MineSweeperGame mines={99} gridSize={[19, 19]} />
        ) : null}
        {windowWidth <= 400 && windowWidth >= 381 ? (
          <MineSweeperGame mines={99} gridSize={[18, 18]} />
        ) : null}

        {windowWidth <= 380 && windowWidth >= 361 ? (
          <MineSweeperGame mines={99} gridSize={[17, 16]} />
        ) : null}

        {windowWidth <= 360 && windowWidth >= 341 ? (
          <MineSweeperGame mines={99} gridSize={[16, 15]} />
        ) : null}

        {windowWidth <= 340 && windowWidth >= 321 ? (
          <MineSweeperGame mines={99} gridSize={[15, 14]} />
        ) : null}
        {windowWidth <= 320 ? (
          <MineSweeperGame mines={99} gridSize={[14, 13]} />
        ) : null}
      </div>
      <div className="MineSweeper__scoreboard_container">
        <ScoreBoard />
      </div>
    </div>
  );
};

export default MineSweeper;
