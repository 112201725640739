import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import galleryLogo from "../../assets/images/gallery-logo.svg";

import Header from "../../components/Header/Header";
import twiter from "../../assets/images/twiter.svg";
import instagram from "../../assets/images/instagram.svg";
import boat from "../../assets/images/boat.svg";
import powered from "../../assets/images/powered-border.svg";
import multiLine from "../../assets/images/multi-line.svg";
import vector from "../../assets/images/vector.svg";
import qr from "../../assets/images/qr.svg";
import Card from "../../components/Card/Card";
import NameSlide from "../../components/NameSlide/NameSlide";
import { RandomReveal } from "react-random-reveal";
import kosmovisionesIcon from "../../assets/images/kosmovisiones-nft.png";
import ScrollBarVertical from "../../components/ScrollBarVertical/ScrollBarVertical";

import "./Kosmovisiones.css";

const Kosmo = () => {

  const wrapperText = useRef(null);
  const [isOverFlow, setIsOverFlow] = useState(false);
  const [wrapperHeight, setWrapperHeight] = useState(0);

  useEffect(() => {
    setWrapperHeight(wrapperText.current.offsetHeight);
    let curOverflow = wrapperText.current.style.overflow;

    if (!curOverflow || curOverflow === "visible")
      wrapperText.current.style.overflow = "hidden";

    let isOverflowing =
      wrapperText.current.clientWidth < wrapperText.current.scrollWidth ||
      wrapperText.current.clientHeight < wrapperText.current.scrollHeight;

    wrapperText.current.style.overflow = curOverflow;

    setIsOverFlow(isOverflowing);
  }, [wrapperText, isOverFlow]);

  console.log(isOverFlow, "isOverFlow");

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
      // id="rodrigo"
    >
      {/* FIXED BARS  */}
      <div className="MainBigSize__stickyBars">
        <div className="MainBigSize__stickyBar_top_container">
          <div className="MainBigSize__stickyBar_top" />
        </div>
        {/* <div className="MainBigSize__stickyBar_bottom_container">
          <ScrollBar />
        </div> */}
      </div>
      <motion.div
        className="Kosmo__container"
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        transition={{
          delay: 0.2,
          stiffness: 100,
          type: "tween",
          duration: 0.5,
        }}
      >
        <div className="Kosmo__wrapper">
          <div className="Kosmo__Header__container">
            <Header
              sectionPhoto={galleryLogo}
              phrase="Designed to exhibit the work of our artists, hosting experiences and events. Both physically and digitally."
            />
          </div>
          <div className="Kosmo__content_wrapper">
            <p>
              <span style={{ background: "black", color: "white" }}>NFT</span>{" "}
              DROPS
            </p>
            <div className="Kosmo__box_container">
              <div className="Kosmo__box_header">
                <NameSlide firtsName="/// BY RODIGO SALCEDO" lastName="KOSMOVISIONES" />
              </div>
              <div className="Kosmo__box_wrapper_content">
                <div className="Kosmo__content_left">
                  <p className="Kosmo_float_numbers">00000 01</p>

                  <div className="Kosmo__card_container">
                    <Card
                      image={kosmovisionesIcon}
                      smallPhrase={`"KOSMOVISIONES" #0000018283`}
                      autor="Created by Rodrigo Salcedo"
                      middlePhrase="sold for 0.04ETH"
                      // imageInfo={false}
                      phrase="The portals are the graphic representation of an awakening moment, they are the point of no return,
                       an interdimensional passage through which one can bridge the regular state of mind with altered states of conciousness"
                    />
                    <div
                      style={{
                        position: "relative",
                        width: "5%",
                        height: "98%",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <p className="Kosmo_float_code">
                        {
                          '{"internalType":"Kosmovisiones","name":"collectionSize2222","type":soul"uint 1"}'
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <div className="Kosmo__content_right">
                  <div className="Kosmo__content_right_content">
                    <div className="Kosmo__content_right_content_text" ref={wrapperText}>
                      <RandomReveal
                        isPlaying
                        duration={4}
                        characterSet="lang:=”en” data-light>”DARK”</head>"
                        revealEasing="linear"
                        characters={`Kósmovisiones is a series of 2222 generative light portals conceptualized and
                        brought to the metaverse by artist Rodrigo Salcedo in collaboration with ITO LAB.
                        For Rodrigo, achieving altered states of consciousness meant an unprecedented
                        rupture in the way we perceive life. He described this metamorphosis of the mind as
                        "the opening of a portal that could never be closed." Now, through recent advances
                        in digital technologies and after one year of artistic exploration, we can share with the metaverse how these transcendental experiences shaped the artistry and vision for this unique series.
                        `}
                      />
                      <br />
                      <br />
                      <RandomReveal
                        isPlaying
                        duration={4}
                        characterSet="lang:=”en” data-light>”DARK”</head>"
                        revealEasing="linear"
                        characters={`The portals are the graphic representation of an awakening moment, they are the
                        point of no return, the interdimensional passage through which one can bridge the
                        physical world with the rest of the kosmos...
                        A genesis series full of magic, spirituality and mysticism, that inhabits both the physical and digital world, establishing an interesting dialogue between both of
                        them, and leaving some questions for the community to ponder: Will technology and its digital tools replace art as we know it? Or is it that both will
                        eventually coexist and live in harmony?
                         Are we as a species destined to inhabit multiple realities?
                        Click the link below to find out.`}
                      />
                    </div>
                    <div
                      style={{
                        height: "95%",
                        width: "auto",
                        marginLeft: "1em",
                        // position: 'absolute',
                        // top: 0,
                        // bottom: 0,
                        // right: '-10px',
                        // zIndex: 3
                        // left: 0
                      }}
                    >
                      {isOverFlow && (
                        <ScrollBarVertical wrapperHeight={wrapperHeight} />
                      )}
                    </div>
                  </div>
                  <div className="Kosmo__content_right_bottom">
                    <div className="Kosmo__content_img">
                      <img src={twiter} alt="" />
                    </div>
                    <div className="Kosmo__content_img">
                      <img src={instagram} alt="" />
                    </div>
                    <div className="Kosmo__content_img">
                      <img src={boat} alt="" />
                    </div>
                    <div className="Kosmo__content_img">
                      <img src={powered} alt="" />
                    </div>
                    <div className="Kosmo__content_img">
                      <img src={multiLine} alt="" />
                    </div>
                    <div className="Kosmo__content_img">
                      <img src={vector} alt="" />
                    </div>
                    <div className="Kosmo__content_img">
                      <img src={qr} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Kosmo;
