import React, { useState, useContext } from "react";
import { motion } from "framer-motion";
import instagramIcon from "../../assets/images/instagram.svg";
import leftArrow from "../../assets/images/left-arrow.svg";
import rightArrow from "../../assets/images/right-arrow.svg";
import longArrow from "../../assets/images/longArrow.svg";
import { AppContext } from "../../context/Context";
import { useController } from "../../hooks/useController";
import boxSlash from "../../assets/images/box-slash.svg";

import "./StudioBox.css";

const StudioBox = ({ infoHeight, boxHeight }) => {
  const { togglePortal } = useContext(AppContext);
  const rodrigoCarousel = useController();
  const andresCarousel = useController();

  const onMouseEnter = () => {
    togglePortal(true);
  };

  const onMouseLeave = () => {
    togglePortal(false);
  };
  return (
    <div
      className="StudioBox__artists__container"
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
    >
      <div className="StudioBox__artist__wrapper">
        <div className="StudioBox__artist">
          <div className="StudioBox__artist_img">
            <div className="StudioBox__artist__letter">
              <p>
                <span>A</span>
              </p>
            </div>
            <div className="StudioBox__artist_controls">
              <a onClick={(e) => rodrigoCarousel.onLeft({ e, imgLength: 12 })}>
                <img src={leftArrow} alt="" />
              </a>
              <div className="StudioBox__image_carousel">
                <img
                  src={require(`../../assets/images/rodrigo/rodrigo-${rodrigoCarousel.imageNumber}.jpeg`)}
                  alt=""
                />
              </div>
              <a onClick={(e) => rodrigoCarousel.onRight({ e, imgLength: 12 })}>
                <img src={rightArrow} alt="" />
              </a>
            </div>
          </div>
          <div className="StudioBox__artist_text">
            <p>///</p>
            <p>RODRIGO</p>
            <p>SALCEDO</p>
          </div>
          <div className="StudioBox__artist_links">
            <div className="StudioBox__artist_insta">
              <a href="https://www.instagram.com/rodrigosalcedo.v/">
                <img src={instagramIcon} alt="" />
              </a>
            </div>
            <div className="StudioBox__artist_more">
              <a href="/rodrigo">
                <p>learn more</p>
                <img src={longArrow} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="StudioBox__artist">
          <div className="StudioBox__artist_img">
            <div className="StudioBox__artist__letter">
              <p>
                <span>B</span>
              </p>
            </div>
            <div className="StudioBox__artist_controls">
              <a onClick={(e) => andresCarousel.onLeft({ e, imgLength: 10 })}>
                <img src={leftArrow} alt="" />
              </a>
              <div className="StudioBox__image_carousel">
                <img
                  src={require(`../../assets/images/andres/andres-${andresCarousel.imageNumber}.JPG`)}
                  alt=""
                />
              </div>
              <a onClick={(e) => andresCarousel.onRight({ e, imgLength: 10 })}>
                <img src={rightArrow} alt="" />
              </a>
            </div>
          </div>
          <div className="StudioBox__artist_text">
            <p>///</p>
            <p>ANDRES</p>
            <p>SALCEDO</p>
          </div>
          <div className="StudioBox__artist_links">
            <div className="StudioBox__artist_insta">
              <a href="https://www.instagram.com/rodrigosalcedo.v/">
                <img src={instagramIcon} alt="" />
              </a>
            </div>
            <div className="StudioBox__artist_more">
              <a href="/andres">
                <p>learn more</p>
                <img src={longArrow} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="StudioBox__artist">
          <div className="StudioBox__artist_img">
            <div className="StudioBox__artist__letter">
              <p>
                <span>C</span>
              </p>
            </div>
            <div className="StudioBox__artist_controls">
              <a>
                <img src={leftArrow} alt="" />
              </a>
              <div className="StudioBox__image_carousel">
                <img
                  src={boxSlash}
                  style={{border: '0px'}}
                  alt=""
                />
              </div>
              <a>
                <img src={rightArrow} alt="" />
              </a>
            </div>
          </div>
          <div className="StudioEmpty__artist_text">
          <p>NOT AVAILABLE</p>
          </div>
          <div className="StudioBox__artist_links">
            <div className="StudioBox__artist_insta">
              <a href="https://www.instagram.com/">
                <img src={instagramIcon} alt="" />
              </a>
            </div>
            <div className="StudioBox__artist_more">
              
            </div>
          </div>
        </div>
        <div className="StudioBox__artist">
          <div className="StudioBox__artist_img">
            <div className="StudioBox__artist__letter">
              <p>
                <span>D</span>
              </p>
            </div>
            <div className="StudioBox__artist_controls">
              <a>
                <img src={leftArrow} alt="" />
              </a>
              <div className="StudioBox__image_carousel">
                <img
                  src={boxSlash}
                  style={{border: '0px'}}
                  alt=""
                />
              </div>
              <a>
                <img src={rightArrow} alt="" />
              </a>
            </div>
          </div>
          <div className="StudioEmpty__artist_text">
          <p>NOT AVAILABLE</p>
          </div>
          <div className="StudioBox__artist_links">
            <div className="StudioBox__artist_insta">
              <a href="https://www.instagram.com/">
                <img src={instagramIcon} alt="" />
              </a>
            </div>
            <div className="StudioBox__artist_more">
              
            </div>
          </div>
        </div>
      </div>
      <div className="StudioBox__artist__scroll">
        <div className="StudioBoxScroll__vertical_container">
          <motion.div
            initial={{ y: 0 }}
            animate={{
              y: boxHeight - (infoHeight + 15),
            }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 7,
            }}
            className="dot__vertical"
          />
          <motion.div
            initial={{ y: 0 }}
            animate={{ y: boxHeight - (infoHeight + 25) }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 4,
            }}
            className="dot__vertical"
          />
          <motion.div
            initial={{ y: 0 }}
            animate={{ y: boxHeight - (infoHeight + 23) }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 6,
            }}
            className="dot__vertical"
          />
          <motion.div
            initial={{ y: 0 }}
            animate={{ y: boxHeight - (infoHeight + 15) }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 10,
            }}
            className="dot__vertical"
          />
          <motion.div
            initial={{ y: 0 }}
            animate={{ y: boxHeight - (infoHeight + 25) }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 8,
            }}
            className="dot__vertical"
          />
        </div>
      </div>
    </div>
  );
};

export default StudioBox;
