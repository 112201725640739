import React from "react";
import { motion } from "framer-motion";
import "./ScrollBarVertical.css";

const ScrollBarVertical = ({ wrapperHeight }) => {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className="container__lamp_vertical">
        <motion.ul
          initial={{ y: 0 }}
          transition={{ duration: 0.5 }}
          className="dot__wrapper_vertical"
        >
          <motion.li
            initial={{ y: 0 }}
            animate={{ y: wrapperHeight - 20 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 3,
            }}
          />
          <motion.li
            initial={{ y: 0 }}
            animate={{ y: wrapperHeight - 20 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 5,
            }}
          />
          <motion.li
            initial={{ y: 0 }}
            animate={{ y: wrapperHeight - 20 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 4,
            }}
          />
          <motion.li
            initial={{ y: 0 }}
            animate={{ y: wrapperHeight - 20 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 6,
            }}
          />
          <motion.li
            initial={{ y: 0 }}
            animate={{ y: wrapperHeight - 20 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 9,
            }}
          />
          <motion.li
            initial={{ y: 0 }}
            animate={{ y: wrapperHeight - 20 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 3,
            }}
          />
          <motion.li
            initial={{ y: 0 }}
            animate={{ y: wrapperHeight - 20 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 7,
            }}
          />
        </motion.ul>
      </div>
    </div>
  );
};

export default ScrollBarVertical;
