import React, { useState } from "react";
import { motion } from "framer-motion";
import CustomForm from "../../components/CustomForm/CustomForm";
import Header from "../../components/Header/Header";
import MineSweeperSmall from "../../components/MineSweeper/MineSweeperSmall";
import InfoDate from "../../components/InfoDate";
import ScrollBar from "../../components/ScrollBar/ScrollBar";

import "./LetsTalkSmall.css";

const LetsTalkSmall = ({ talkRef }) => {
  const [isFilter, setIsFilter] = useState(true);

  const handleShowFilter = () => {
    setIsFilter(false);
  };

  return (
    <motion.div
      initial={{ x: "100vw" }}
      animate={{ x: 0 }}
      transition={{
        delay: 0.2,
        stiffness: 100,
        type: "tween",
        duration: 0.5,
      }}
      className="LetsTalkSmall__container"
      ref={talkRef}
    >
      <div className="LetsTalkSmall__wrapper">
        <div className="LetsTalkSmall__Header_contaner">
          <Header
            phrase="We can´t wait to hear to hear from you, dont forget to submit your enquiry 
            the metaverse is waiting for you... "
          />
        </div>
        <div className="LetsTalkSmall__maincontent_wrapper">
          <div className="LetsTalkSmall__maincontent">
            <div className="LetsTalkSmall__form__container">
              <div className="LetsTalkSmall__floatNumbers">
                <p>01001001 01010100 01001111 00100000</p>
              </div>
              <div className="LetsTalkSmall__form">
                <CustomForm handleShowFilter={handleShowFilter} />
              </div>
            </div>
            <div className="LetsTalkSmall__sweeper__container">
              <MineSweeperSmall isFilter={isFilter} />
            </div>
            <div className="LetsTalkSmall__info__container">
              <InfoDate />
            </div>
          </div>
        </div>
        <div style={{ width: "99.2%" }}>
          <ScrollBar />
        </div>
      </div>
    </motion.div>
  );
};

export default LetsTalkSmall;
