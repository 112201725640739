import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import Header from "../../components/Header/Header";
import DesignBox from "../../components/DesignBox/DesignBox";
import Infodate from "../../components/InfoDate";
import designLogo from "../../assets/images/design-logo.svg";
import { Portal } from "react-portal";
import stickBox from "../../assets/images/border-text.svg";
import title from "../../assets/images/creative-lab.svg";
import title2 from "../../assets/images/direction-production.svg";

import "./ItoDesign.css";

const ItoDesign = () => {
  const boxWrapper = useRef(null);
  const info = useRef(null);

  const [boxHeight, setBoxHeight] = useState(0);
  const [infoHeight, setInfoHeight] = useState(0);

  useEffect(() => {
    const boxHeight = boxWrapper.current.offsetHeight;

    const infoHeight = info.current.offsetHeight;
    setBoxHeight(boxHeight);
    setInfoHeight(infoHeight);
  }, []);

  return (
    <motion.div
      className="Design__container"
      initial={{ x: "100vw" }}
      animate={{ x: 0 }}
      transition={{
        delay: 0.2,
        stiffness: 100,
        type: "tween",
        duration: 0.5,
      }}
    >
      <div className="Design__wrapper">
        <div className="Design__Header__container">
          <Header
            sectionPhoto={designLogo}
            phrase="ITO 3DSGN is where most of the magic happens, here we create all of our digital
            art, including renders, animations, UI/UX and a wide variety of graphics.."
          />
        </div>
        <div className="Design__content_wrapper">
          <div className="Design__left__container">
            <div className="Design__subtitle__container">
              <img src={title} alt="" />
            </div>
            <div className="Design__text__container">
              <div className="Design__text__left">
                <p>
                  Alongside our blockchain specialists we are equiped to develop
                  any type of digital asset, enough to be able to complement
                  your vision and bring it to web3..
                </p>
                <p>
                  Through our specialized services, we can find offerings that
                  go from art direction and production of artistic projects, to
                  a complete set of integral branding services.
                </p>
                <p>
                  With effort we have gathered an unprecedented team of talents,
                  all brought together to complement the creative needs of web 3
                  ,able to potentialize any projects and take them to higher
                  levels.
                </p>
                <p>
                  ITO LAB thrives from collaborating with emergent artists, tech
                  companies and multidisciplinary creative projects, helping in
                  the process to transcend their artistic vision into to the
                  digital world (or the other way around).
                </p>
                <p>
                  We tailor our packages to perfectly accommodate any of our
                  clients needs.
                </p>
              </div>
            </div>
          </div>
          <div className="Design__right__container">
            <div className="Design__subtitle__container2">
              <img src={title2} alt="" />
            </div>
            <div className="Design__portal__container" ref={boxWrapper}>
              <div className="Design__portal__wrapper">
                <DesignBox boxHeight={boxHeight} infoHeight={infoHeight} />
                <div ref={info}>
                  <Infodate />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ItoDesign;
