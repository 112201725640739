import React, { useState } from "react";
import { motion } from "framer-motion";
import Header from "../../components/Header/Header";
import galleryLogo from "../../assets/images/gallery-logo.svg";
import NameSlide from "../../components/NameSlide/NameSlide";
import isiaIcon from "../../assets/images/isia-nft.svg";

import mundiIcon from "../../assets/images/mundi.png";
import shades from "../../assets/images/shades.svg";
import dotQr from "../../assets/images/dot-qr.png";
import powered from "../../assets/images/powered.svg";
import vector from "../../assets/images/vector.svg";
// import itoIcon from "../../assets/images/ITOLAB.svg";
import itoIcon from "../../assets/images/itolab.png";
import bar from "../../assets/images/bar.svg";
import { RandomReveal } from "react-random-reveal";

import "./IsiaSmall.css";

const IsiaSmall = () => {
  return (
    <motion.div
      initial={{ y: "100vw" }}
      animate={{ y: 0 }}
      transition={{
        delay: 0.2,
        stiffness: 100,
        type: "tween",
        duration: 0.5,
      }}
      style={{
        width: "100%",
        height: `${window.innerHeight}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        overflow: "hidden",
        // alignItems: "center",
      }}
    >
      {/* FIXED BARS  */}
      <div className="IsiaSmall__stickyBars">
        <div className="IsiaSmall__stickyBar_top_container">
          <div className="IsiaSmall__stickyBar_top" />
        </div>
      </div>
      <div
        style={{
          width: "80%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <div className="IsiaSmall__wrapper">
          <div className="IsiaSmall__Header_contaner">
            <Header
              sectionPhoto={galleryLogo}
              phrase="Designed to exhibit the work of our artists, hosting experiences and events. Both physically and digitally."
            />
          </div>
          <div className="IsiaSmall__maincontent_wrapper">
            <div className="IsiaSmall__subtitle">
              <h3>
                <span>NFT</span> DROPS
              </h3>
            </div>
            <div className="IsiaSmall__box_container">
              <div className="IsiaSmall__box_header">
                <NameSlide firtsName="BY ALEXANDRO" lastName="GUSSO ///" />
              </div>
              <div className="IsiaSmall__box_content">
                <div className="IsiaSmall__box_text">
                <RandomReveal
                        isPlaying
                        duration={4}
                        characterSet="lang:=”en” data-light>”DARK”</head>"
                        revealEasing="linear"
                        characters={`ISIA is part of “ F3lings” a collection of 3 1/1 fine art pieces  representing the 3 most important feelings in a human, 
                        where with a minimalist animation we achieve transport you into different feelings.`}
                      />
                      <br />
                      <br />
                      <RandomReveal
                        isPlaying
                        duration={4}
                        characterSet="lang:=”en” data-light>”DARK”</head>"
                        revealEasing="linear"
                        characters={`For Alexandro Gusso the minimalism and the environment creation takes a great part in his creative side of his NFT’s. 
                        where he tries to always implement a good lighting setup and a original and surreal environment. Looking for new implementations and ways to introduce an original way of creating 3D work Alexandro tries the best and new technologies to create the best result.`}
                      />
                      <br />
                      <br />
                      <RandomReveal
                        isPlaying
                        duration={4}
                        characterSet="lang:=”en” data-light>”DARK”</head>"
                        revealEasing="linear"
                        characters={`Lead 3D artist of the collection “Antonym” a collection of 8888 NFTs  where he learn all the implications of 
                        creating a 3D collection with fine details and a creative direction with a physical and digital asset in game.`}
                      />
                </div>
                {/* <div className="ArtistSmall__content_right_content_button">
                  <a href="/appointment">appointments</a>
                </div> */}
                <div className="IsiaSmall__card_container">
                  <div className="IsiaSmall__card_numbers">
                    <p>0000 01</p>
                  </div>
                  <div className="IsiaSmall__card_wrapper">
                    <div className="IsiaSmall__card_img">
                      <img src={isiaIcon} alt="" />
                      <div className="IsiaSmall__bottom_container">
                        <div className="IsiaSmall__bottom_text" style={{minHeight: '205px'}}>

                          <p>"ISIA" #0000018283</p>
                          <p>Created by Alexandro Gusso</p>
                          <p>
                            <span>sold for </span>
                            0.35ETH
                          </p>
                          <p>
                          Feeling is the representation of various sentiments in a minimalist way,
                          taking artistic elements and geometric elements to represent these in a 
                          larger spectrum of how we sense these feelings.
                          </p>
                        </div>
                        <div className="IsiaSmall__bottom_images">
                          <div className="IsiaSmall__bottom_img_left">
                            <div className="IsiaSmall__bottom_img_left_section_1">
                              <img src={powered} alt="" />
                              <img src={itoIcon} alt="" />
                            </div>
                            <div className="IsiaSmall__bottom_img_left_section_2">
                              <img src={bar} alt="" />
                              <img src={vector} alt="" />
                            </div>
                          </div>
                          <div className="IsiaSmall__bottom_img_right">
                            <div className="IsiaSmall__bottom_img_right_section_1">
                              {/* <img src={mundiIcon} alt="" /> */}
                            </div>
                            <div className="IsiaSmall__bottom_img_right_section_2">
                              <img src={shades} alt="" />
                              <img src={dotQr} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="IsiaSmall__card_text">
                      <p>
                        {
                          '{"internalType":"ISIA","name":"collectionSize2222","type":soul"uint1"}'
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div style={{ width: "100%" }}>
          <ScrollBar />
        </div> */}
      </div>
    </motion.div>
  );
};

export default IsiaSmall;
