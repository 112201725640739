import React, { useEffect, useState } from "react";
import MineSweeperGame from "pb-minesweeper";
import ScoreBoard from "../ScoreBoard/ScoreBoard";
import FilterLock from "../FilterLock/FilterLock";
import "./MineSweeper.css";

const MineSweeper = ({ isFilter }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  const checkWidth = (height) => {
    if (windowWidth >= 1800) {
      return <MineSweeperGame mines={99} gridSize={[41, height]} />;
    }

    if (windowWidth >= 1600 && windowWidth <= 1799) {
      return <MineSweeperGame mines={99} gridSize={[35, height]} />;
    }

    if (windowWidth >= 1400 && windowWidth <= 1599) {
      return <MineSweeperGame mines={99} gridSize={[29, height]} />;
    }

    if (windowWidth >= 1100 && windowWidth <= 1399) {
      return <MineSweeperGame mines={99} gridSize={[23, height]} />;
    }

    if (windowWidth >= 951 && windowWidth <= 1099) {
      return <MineSweeperGame mines={99} gridSize={[19, height]} />;
    }
  };

  const checkHeight = () => {
    if (windowHeight >= 831) {
      return checkWidth(17); //Better row number for this screen size
    }

    if (windowHeight >= 781 && windowHeight <= 830) {
      return checkWidth(14); //Better row number for this screen size
    }

    if (windowHeight >= 731 && windowHeight <= 780) {
      return checkWidth(11); //Better row number for this screen size
    }

    if (windowHeight >= 691 && windowHeight <= 730) {
      return checkWidth(12); //Better row number for this screen size
    }

    if (windowHeight >= 651 && windowHeight <= 690) {
      return checkWidth(10); //Better row number for this screen size
    }

    if (windowHeight >= 500 && windowHeight <= 650) {
      return checkWidth(9); //Better row number for this screen size
    }
  };

  console.log(checkHeight(), "height");

  return (
    <div className="MineSweeper__wrapper">
      <div className="MineSweeper__container">
        {isFilter && <FilterLock />}
        {/* DESKTOP VIEW */}

        {checkHeight()}
      </div>
      <div className="MineSweeper__scoreboard_container">
        <ScoreBoard />
      </div>
    </div>
  );
};

export default MineSweeper;
