import React, { useState } from "react";
import { motion } from "framer-motion";
import Header from "../../components/Header/Header";
import studioLogo from "../../assets/images/studio-logo.svg";
import titleIcon from "../../assets/images/titleAppointment.svg";
import FormBooking from "../Appointment/FormBooking";
import MineSweeper from "../../components/MineSweeper/MineSweeperSmall";
import InfoDate from "../../components/InfoDate";

import "./AppointmentSmall.css";

const AppointmentSmall = () => {
  const [isFilter, setIsFilter] = useState(true);

  const hiddenFilter = () => {
    setIsFilter(false);
  };

  return (
    <div
      style={{
        width: "100%",
        height: `${window.innerHeight}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      {/* FIXED BARS  */}
      <div className="AppointmentSmall__stickyBars">
        <div className="AppointmentSmall__stickyBar_top_container">
          <div className="AppointmentSmall__stickyBar_top" />
        </div>
      </div>
      <motion.div
        initial={{ x: "100vw" }}
        animate={{ x: 0 }}
        transition={{
          delay: 0.2,
          stiffness: 100,
          type: "tween",
          duration: 0.5,
        }}
        className="AppointmentSmall__container"
      >
        <div className="AppointmentSmall__wrapper">
          <div className="AppointmentSmall__Header_contaner">
            <Header
              sectionPhoto={studioLogo}
              phrase="ITO STUDIO is an inhouse contemporary tattoo studio, we succesfully implemented blockchain
              technology into tattooing as a case study, a place
              where the physical world collides with the digital one and manages to coexist
              in perfect harmony."
            />
          </div>
          <div className="AppointmentSmall__maincontent_wrapper">
            <div className="AppointmentSmall__maincontent">
              <div className="AppointmentSmall__title_container">
                <img src={titleIcon} alt="" />
              </div>
              <div className="AppointmentSmall__form_container">
                <FormBooking quitFilter={hiddenFilter} />
              </div>
              <div className="AppointmentSmall__game_container">
                <MineSweeper isFilter={isFilter} />
              </div>
              <div className="AppointmentSmall__infodate_container">
                <InfoDate />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default AppointmentSmall;
