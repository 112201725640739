import React from "react";
import thunderIcon from "../../assets/images/thunder.svg";
import handlerIcon from "../../assets/images/handler.svg";
import statutteIcon from "../../assets/images/statutte-minimal.svg";
import networkingIcon from "../../assets/images/networking.svg";
import networkingMidIcon from "../../assets/images/networking-mid.svg";
import { motion } from "framer-motion";

import "./DesignBoxSmall.css";

const DesignBoxSmall = ({ boxHeight }) => {
  return (
    <div className="DesignBoxSmall__container">
      <div className="DesignBoxSmall__wrapper">
        {/* FIRTS SIDE */}
        <div className="DesignBoxSmall__item__container">
          <div className="DesignBoxSmall__item__wrapper">
            <div className="DesignBoxSmall__item__left">
              <div className="DesignBoxSmall__item_head">
                <img src={thunderIcon} alt="" /> <p>Secondary media</p>
              </div>
              <p>
                We can help your project Creation and development of digital
                assets for social media: We will take the utilities and perks of
                NFTs as a medium to unlock new revenues and potentialize
                engagement with your audience and clientele.
              </p>
            </div>
            <div className="DesignBoxSmall__item__right">
              <img src={handlerIcon} alt="" />
            </div>
          </div>
        </div>
        {/* SECOND SIDE */}
        <div className="DesignBoxSmall__item__container">
          <div className="DesignBoxSmall__item__wrapper">
            <div className="DesignBoxSmall__item__left">
              <div className="DesignBoxSmall__item_head">
                <img src={thunderIcon} alt="" /> <p>Nft collection</p>
              </div>
              <p>
                Ito lab will provide you and your team with all the necessary
                tools to succesfully manage your NFT collection, from strategic
                advising to artistic direction or creative production, we can
                potentialize your vision and bring it into web 3 successfully,
              </p>
            </div>
            <div className="DesignBoxSmall__item__right">
              <img src={statutteIcon} alt="" />
            </div>
          </div>
        </div>
        <div className="DesignBoxSmall__item__container">
          <div className="DesignBoxSmall__item__wrapper">
            <div className="DesignBoxSmall__item__left">
              <div className="DesignBoxSmall__item_head">
                <img src={thunderIcon} alt="" /> <p>Transition 101</p>
              </div>
              <p>
                Whith our creative input and our tools, you will be able to
                transition your physical art to the digital world and the other
                way around.
              </p>
            </div>
            <div className="DesignBoxSmall__item__right">
              <img src={networkingIcon} alt="" />
            </div>
          </div>
        </div>
        <div className="DesignBoxSmall__item__container">
          <div className="DesignBoxSmall__item__wrapper">
            <div className="DesignBoxSmall__item__left">
              <div className="DesignBoxSmall__item_head">
                <img src={thunderIcon} alt="" /> <p>Integration</p>
              </div>
              <p>
                If you are looking to integrate Digital technology into your
                business but don’t know where to start we can help you achieve
                the unimaginable. (see ito studio as a successful use case).
              </p>
            </div>
            <div className="DesignBoxSmall__item__right">
              <img
                src={networkingMidIcon}
                alt=""
                style={{ width: "60%", height: "60%" }}
              />
            </div>
          </div>
        </div>
        <div className="DesignBox__table__container">
          <div className="DesignBox__item__wrapper_table">
            <div className="DesignBox__item_head_table">
              <img src={thunderIcon} alt="" /> <p>Integral Branding</p>
            </div>
            <div className="DesignBox__table">
              <table>
                <tr>
                  <td>Websites</td>
                  <td>Digital ads</td>
                  <td>Stationery</td>
                </tr>
                <tr>
                  <td>Mobile apps</td>
                  <td>Infographics</td>
                  <td>Billboards</td>
                </tr>
                <tr>
                  <td>Logos & branding</td>
                  <td>Icons</td>
                  <td>Signage</td>
                </tr>
                <tr>
                  <td>Design systems</td>
                  <td>Blog graphics</td>
                  <td>Brand guides</td>
                </tr>
                <tr>
                  <td>Slide decks</td>
                  <td>Wireframes</td>
                  <td> -</td>
                </tr>
                <tr>
                  <td>UI / UX design</td>
                  <td>Social media graphics</td>
                  <td> -</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        className="StudioBox__artist__scroll"
        // style={{ height: `calc(${boxHeight}px - ${infoHeight}px + 5px)` }}
      >
        <div className="StudioBoxScroll__vertical_container">
          <motion.div
            initial={{ y: 0 }}
            animate={{
              y: boxHeight - 15,
            }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 7,
            }}
            className="dot__vertical"
          />
          <motion.div
            initial={{ y: 0 }}
            animate={{ y: boxHeight - 25 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 4,
            }}
            className="dot__vertical"
          />
          <motion.div
            initial={{ y: 0 }}
            animate={{ y: boxHeight - 20 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 6,
            }}
            className="dot__vertical"
          />
          <motion.div
            initial={{ y: 0 }}
            animate={{ y: boxHeight - 10 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 10,
            }}
            className="dot__vertical"
          />
          <motion.div
            initial={{ y: 0 }}
            animate={{ y: boxHeight - 28 }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 8,
            }}
            className="dot__vertical"
          />
        </div>
      </div>
    </div>
  );
};

export default DesignBoxSmall;
