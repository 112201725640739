import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import Header from "../../components/Header/Header";
import ScrollBar from "../../components/ScrollBar/ScrollBar";
import designLogo from "../../assets/images/design-logo.svg";
import InfoDate from "../../components/InfoDate";
import DesignBoxSmall from "../../components/DesignBox/DesignBoxSmall";
import stickBox from "../../assets/images/border-text.svg";
import title from "../../assets/images/creative-lab.svg";
import title2 from "../../assets/images/direction-production.svg";
import "./DesignSmall.css";

const ItoDesignSmall = ({ designRef }) => {
  const wrapperBox = useRef(null);
  const info = useRef(null);
  const [infoHeight, setInfoHeight] = useState(0);
  const [boxHeight, setBoxHeight] = useState(0);

  useEffect(() => {
    const infoHeight = info.current.offsetHeight;
    const boxHeight = wrapperBox.current.offsetHeight;

    setBoxHeight(boxHeight);
    setInfoHeight(infoHeight);
  }, []);
  return (
    <motion.div
      initial={{ x: "100vw" }}
      animate={{ x: 0 }}
      transition={{
        delay: 0.2,
        stiffness: 100,
        type: "tween",
        duration: 0.5,
      }}
      className="DesignSmall__container"
      ref={designRef}
      id="design"
    >
      <div className="DesignSmall__wrapper">
        <div className="DesignSmall__Header_container">
          <Header
            sectionPhoto={designLogo}
            phrase="Designed to exhibit the work of our artists, hosting experiences and events. Both physically and digitally."
          />
        </div>
        <div className="DesignSmall__maincontent_wrapper">
          <div className="DesignSmall__maincontent">
            <div className="DesignSmall__subtitle">
              <img src={title} alt="" />
            </div>
            <div style={{ width: "100%" }}>
              <div className="DesignSmall__box_text">
                <p>
                  Alongside our blockchain specialists we are equiped to develop
                  any type of digital asset, enough to be able to complement
                  your vision and bring it to web3..
                </p>
                <p>
                  Through our specialized services, we can find offerings that
                  go from art direction and production of artistic projects, to
                  a complete set of integral branding services.
                </p>
                <p>
                  ITO LAB thrives from collaborating with emergent artists, tech
                  companies and multidisciplinary creative projects, helping in
                  the process to transcend their artistic vision into to the
                  digital world (or the other way around).
                </p>
                <p>
                  We tailor our packages to perfectly accommodate any of our
                  clients needs.
                </p>
              </div>
              <div className="DesignSmall__subtitle2">
                <img src={title2} alt="" />
              </div>
              <div ref={wrapperBox} style={{ height: "450px", width: "100%" }}>
                <div style={{ height: "100%" }}>
                  <DesignBoxSmall boxHeight={boxHeight} />
                  <div
                    style={{ width: "100%", paddingBottom: "1em" }}
                    ref={info}
                  >
                    <InfoDate />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "99.2%" }}>
          <ScrollBar />
        </div>
      </div>
    </motion.div>
  );
};

export default ItoDesignSmall;
