import React from "react";
import messageIcon from "../../assets/images/message-minesweeper.svg";
import "./FilterLock.css";

const FilterLock = () => {
  return (
    <div className="FilterLock__container">
      <img src={messageIcon} alt="" />
    </div>
  );
};

export default FilterLock;
