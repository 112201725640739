import React, { useState } from "react";
import { motion } from "framer-motion";
import Header from "../../components/Header/Header";
import studioLogo from "../../assets/images/studio-logo.svg";
import NameSlide from "../../components/NameSlide/NameSlide";
import andresIcon from "../../assets/images/andres-card.JPG";

import mundiIcon from "../../assets/images/mundi.png";
import shades from "../../assets/images/shades.svg";
import dotQr from "../../assets/images/dot-qr.png";
import powered from "../../assets/images/powered.svg";
import vector from "../../assets/images/vector.svg";
import itoIcon from "../../assets/images/ITOLAB.svg";
import bar from "../../assets/images/bar.svg";
import { RandomReveal } from "react-random-reveal";

// import "./ArtistSmall.css";

const AndresSmall = () => {
  return (
    <motion.div
      initial={{ y: "100vw" }}
      animate={{ y: 0 }}
      transition={{
        delay: 0.2,
        stiffness: 100,
        type: "tween",
        duration: 0.5,
      }}
      style={{
        width: "100%",
        height: `${window.innerHeight}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        overflow: "hidden",
        // alignItems: "center",
      }}
    >
      {/* FIXED BARS  */}
      <div className="ArtistSmall__stickyBars">
        <div className="ArtistSmall__stickyBar_top_container">
          <div className="ArtistSmall__stickyBar_top" />
        </div>
      </div>
      <div
        style={{
          width: "80%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <div className="ArtistSmall__wrapper">
          <div className="ArtistSmall__Header_contaner">
            <Header
              sectionPhoto={studioLogo}
              phrase="ITO STUDIO is an inhouse contemporary tattoo studio, we succesfully implemented blockchain
              technology into tattooing as a case study, a place
              where the physical world collides with the digital one and manages to coexist
              in perfect harmony."
            />
          </div>
          <div className="ArtistSmall__maincontent_wrapper">
            <div className="ArtistSmall__subtitle">
              <h3>
                <span>RESIDENT</span> ARTIST
              </h3>
            </div>
            <div className="ArtistSmall__box_container">
              <div className="ArtistSmall__box_header">
                <NameSlide firtsName="ANDRES" lastName="SALCEDO ///" />
              </div>
              <div className="ArtistSmall__box_content">
                <div className="ArtistSmall__box_text">
                  <RandomReveal
                    isPlaying
                    duration={4}
                    characterSet="lang:=”en” data-light>”DARK”</head>"
                    revealEasing="linear"
                    characters="Andres Salcedo Velarca is an artist born in Guadalajara City, Mexico.
                     Since a young age he has been surrounded by art, inspired by it, he decided to study Architecture for over two years. 
                     After exploring his potential in the art world he took the decision to drop out of college
                      and try new ways to reach and inspire society at large. Starting in the world of contemporary tattoo, 
                      in a little over a year he has gained wide recognition allowing him to guest at the top tier studios around the world. 
                      After participating in his first art show last November, he has continued to explore illustration and painting, 
                      in addition to constantly perfecting his tattooing technique. He currently resides in Mexico City and is part of itolab."
                  />
                </div>
                <div className="ArtistSmall__content_right_content_button">
                  <a href="/appointment">appointments</a>
                </div>
                <div className="ArtistSmall__card_container">
                  <div className="ArtistSmall__card_numbers">
                    <p>0000 01</p>
                  </div>
                  <div className="ArtistSmall__card_wrapper">
                    <div className="ArtistSmall__card_img">
                      <img src={andresIcon} alt="" />
                      <div className="ArtistSmall__bottom_container">
                        <div className="ArtistSmall__bottom_text">
                          <p>“Tatooing is more than lines it is art”</p>
                          <p>Andres Salcedo</p>
                          <p>
                            <span>[Loc. *] </span>
                            40.7548ºN, 73.9774ºW
                          </p>
                          <p>
                            The portals are the graphic representation of an
                            awakening moment, they are the point of no return,
                            an interdimensional passage through which one can
                            bridge the regular state of mind with altered states
                            of conciousness
                          </p>
                        </div>
                        <div className="ArtistSmall__bottom_images">
                          <div className="ArtistSmall__bottom_img_left">
                            <div className="ArtistSmall__bottom_img_left_section_1">
                              <img src={powered} alt="" />
                              <img src={itoIcon} alt="" />
                            </div>
                            <div className="ArtistSmall__bottom_img_left_section_2">
                              <img src={bar} alt="" />
                              <img src={vector} alt="" />
                            </div>
                          </div>
                          <div className="ArtistSmall__bottom_img_right">
                            <div className="ArtistSmall__bottom_img_right_section_1"></div>
                            <div className="ArtistSmall__bottom_img_right_section_2">
                              <img src={shades} alt="" />
                              <img src={dotQr} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ArtistSmall__card_text">
                      <p>
                        {
                          '{"internalType":"Kosmovisiones","name":"collectionSize2222","type":soul"uint1"}'
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div style={{ width: "100%" }}>
          <ScrollBar />
        </div> */}
      </div>
    </motion.div>
  );
};

export default AndresSmall;
