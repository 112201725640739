import React, { useContext } from "react";
import { motion } from "framer-motion";
import boxSlash from "../../assets/images/box-slash.svg";

import longArrow from "../../assets/images/longArrow.svg";
import { AppContext } from "../../context/Context";

import "./EmptyBox.css";

const EmptyBox = ({ infoHeight, boxHeight }) => {
  const { togglePortal } = useContext(AppContext);

  const onMouseEnter = () => {
    togglePortal(true);
  };

  const onMouseLeave = () => {
    togglePortal(false);
  };
  return (
    <div
      className="GalleryEmpty__artists__container"
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
    >
      <div className="GalleryEmpty__artist__wrapper">
        <div className="GalleryEmpty__artist">
          <div className="GalleryEmpty__artist_img">
            <span>A</span>
            <div className="GalleryEmpty__artist_controls">
              <img src={boxSlash} alt="" />
            </div>
          </div>
          <div className="GalleryEmpty__artist_text">
            <p>NO EVENTS IN THE CALENDAR</p>
          </div>
          <div className="GalleryEmpty__artist_links">
            <div className="GalleryEmpty__artist_insta"></div>
            <div className="GalleryEmpty__artist_more">
              <a href="/artist">
                <p>Learn more</p>
                <img src={longArrow} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="GalleryEmpty__artist">
          <div className="GalleryEmpty__artist_img">
            <span>B</span>
            <div className="GalleryEmpty__artist_controls">
              <img src={boxSlash} alt="" />
            </div>
          </div>
          <div className="GalleryEmpty__artist_text">
            <p>NO EVENTS IN THE CALENDAR</p>
          </div>
          <div className="GalleryEmpty__artist_links">
            <div className="GalleryEmpty__artist_insta"></div>
            <div className="GalleryEmpty__artist_more">
              <a href="/artist">
                <p>Learn more</p>
                <img src={longArrow} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="GalleryEmpty__artist__scroll">
        <div className="GalleryEmptyScroll__vertical_container">
          <motion.div
            initial={{ y: 0 }}
            animate={{
              y: boxHeight - (infoHeight + 15),
            }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 7,
            }}
            className="dot__vertical"
          />
          <motion.div
            initial={{ y: 0 }}
            animate={{ y: boxHeight - (infoHeight + 25) }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 4,
            }}
            className="dot__vertical"
          />
          <motion.div
            initial={{ y: 0 }}
            animate={{ y: boxHeight - (infoHeight + 23) }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 6,
            }}
            className="dot__vertical"
          />
          <motion.div
            initial={{ y: 0 }}
            animate={{ y: boxHeight - (infoHeight + 15) }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 10,
            }}
            className="dot__vertical"
          />
          <motion.div
            initial={{ y: 0 }}
            animate={{ y: boxHeight - (infoHeight + 25) }}
            transition={{
              repeat: Infinity,
              repeatType: "reverse",
              duration: 8,
            }}
            className="dot__vertical"
          />
        </div>
      </div>
    </div>
  );
};

export default EmptyBox;
