import React, { useState } from "react";

export const AppContext = React.createContext();

const ItoContext = ({ children }) => {
  const [parallax, setParallax] = useState(null);
  const [isNavBarFixed, setIsNavBarFixed] = useState(true);
  const [isTalk, setIsTalk] = useState(false);
  const [isStudio, setIsStudio] = useState(false);
  const [isGallery, setIsGallery] = useState(false);
  const [isDesign, setIsDesign] = useState(false);
  const [isAbout, setIsAbout] = useState(false);
  const [isPortal, setIsPortal] = useState(false);
  const [isDropdown, setIsDropDown] = useState(false);

  const toggleNavBarPosition = (value) => {
    setIsNavBarFixed(value);
  };

  const togglePortal = (value) => {
    setIsPortal(value);
  };

  const toggleDropDown = (value) => {
    setIsDropDown(value);
  };

  const setParallaxRef = (ref) => {
    setParallax(ref);
  };

  return (
    <AppContext.Provider
      value={{
        isNavBarFixed,
        toggleNavBarPosition,
        setIsTalk,
        isTalk,
        isStudio,
        setIsStudio,
        isGallery,
        setIsGallery,
        setIsDesign,
        isDesign,
        isAbout,
        setIsAbout,
        togglePortal,
        isPortal,
        isDropdown,
        toggleDropDown,
        parallax,
        setParallaxRef,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default ItoContext;
