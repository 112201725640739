import React, { useState } from "react";
import upload from "../../assets/images/upload.png";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import Location from "../Location/Location";
import useForm from "../../hooks/useForm";
import { countries } from "../../Countrys.jsx";
import GetInTouch from "../GetInTouch";
import WorkWithUs from "../WorkWithUs";
import InfoDateVertical from "../InfoDateVertical/InfoDateVertical";
import Spinner from "../Spinner";
import "./CustomForm.css";
import { Base64 } from "js-base64";

const CustomForm = ({ handleShowFilter, isInfoDate }) => {
  const [isWork, setIsWork] = useState(false);

  const [error, setError] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [fileName, setFileName] = useState("");
  const [inputFile, setInputFile] = useState("");
  const [inputBase64, setInputBase64] = useState('');
  const [inputExtension, setInpuExtension] = useState('');
  const [resetDefault, setResetDefault] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formValues, handleInputChange, reset] = useForm({
    name: "",
    email: "",
    message: "",
    link: "",
  });

  const { name, email, message, link } = formValues;

  const [selects, setSelects] = useState({
    country: "",
    area: "",
    service: "",
  });

  const { country, area, service } = selects;



  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
    });
  };

  const validateEmail = (email) => {
    const expReg =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    let isValid = expReg.test(email);

    if (!isValid) {
      setErrorEmail(true);
    } else {
      setErrorEmail(false);
    }
    return isValid;
  };

  const validateInputFile = async(e) => {
    let files = e.target.files[0];
    let extension = e.target.files[0].name.slice((files.name.length - 3), files.name.length)

    try {
      const base64 = await toBase64(files);
      setInputBase64(base64);
      setInputFile(files)
      setFileName(files.name);
      setInpuExtension(extension)
    } catch (error) {
      console.log(error);
    }

  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (isWork) {
      if (
        !name.trim() ||
        !email.trim() ||
        !message.trim() ||
        !country.trim() ||
        !area.trim()
      ) {
        setError(true);

        return;
      }

    }

    if (!isWork) {
      if (
        !name.trim() ||
        !email.trim() ||
        !message.trim() ||
        !country.trim() ||
        !service.trim()
      ) {
        setError(true);
        return;
      }
    }

    if (!validateEmail(email)) {
      return;
    }

    const getIn = {
      name,
      email,
      country,
      service,
      message,
      subject: "Get in touch",
      type: 1,
    };

    const workWithUs = {
      name,
      email,
      country,
      area,
      message,
      link,
      inputFile,
      inputBase64,
      inputExtension,
      subject: "Work with us",
      type: 1,
    };

    setLoading(true);

    console.log(workWithUs, 'workWithUs')

    //Build
    fetch("apiV1/mail.php", {
      //loca
      //fetch("http://localhost:8000/apiV1/mailAppoint.php", {
      method: "POST",
      body: JSON.stringify(!isWork ? getIn : workWithUs),
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSelects({
          country: "",
          area: "",
          service: "",
        });

        setInputBase64("");
        setFileName("");
        setResetDefault(true);

        reset();
        // document.getElementById("inputFile").value = null;

        handleShowFilter();
      })
      .catch((e) => {
        setErrorApi(true);
        console.log(e, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const services = [
    {
      id: 1,
      name: "Collaboration",
    },
    {
      id: 2,
      name: "Consulting",
    },
    {
      id: 3,
      name: "I'm an artist",
    },
    {
      id: 4,
      name: "Other",
    },
  ];

  const areas = [
    {
      id: 1,
      name: "Artist",
    },
    {
      id: 2,
      name: "Tatooist",
    },
    {
      id: 3,
      name: "3d Designer",
    },
    {
      id: 4,
      name: "Other",
    },
  ];

  return (
    <div className="CustomForm__container">
      <div className="CustomForm__wrapper">
        <div className="CustomForm__form">
          {/* buttons */}
          <div className="CustomForm__buttons_container">
            <div style={{ display: "flex" }}>
              <div
                id="title"
                onClick={() => setIsWork(false)}
                className={
                  !isWork ? "CustomForm__button_active" : "CustomForm__button"
                }
              >
                Get in touch
              </div>
              <div
                id="title"
                onClick={() => setIsWork(true)}
                className={
                  isWork ? "CustomForm__button_active" : "CustomForm__button"
                }
                style={{ marginLeft: "1em" }}
              >
                Work with us
              </div>
            </div>
            <div className="CustomForm__icon_arrow">{">>"}</div>
          </div>
          {/* END buttons */}

          {isWork ? (
            <div className="CustomForm__form_container">
              <form style={{ width: "100%" }}>
                <div className="CustomForm__form_wrapper">
                  <input
                    className="CustomForm__form_input_text"
                    placeholder="Name"
                    name="name"
                    value={name}
                    onChange={handleInputChange}
                  />
                  <input
                    type="text"
                    placeholder="Email"
                    className="CustomForm__form_input_text"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </div>
                <div className="CustomForm__form_select_container">
                  <CustomSelect
                    defaultText="Country"
                    optionsList={countries}
                    selects={selects}
                    setSelects={setSelects}
                    resetDefault={resetDefault}
                    setResetDefault={setResetDefault}
                  />
                  <CustomSelect
                    defaultText="Area"
                    optionsList={areas}
                    selects={selects}
                    setSelects={setSelects}
                    resetDefault={resetDefault}
                    setResetDefault={setResetDefault}
                  />
                </div>
                <div>
                  <textarea
                    style={{ resize: "none" }}
                    type="text"
                    placeholder="Introduce Yourself"
                    className="CustomForm__form_input_area"
                    name="message"
                    value={message}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="CustomFrom__upload_wrapper">
                  <div className="CustomForm__upload_container">
                    {inputFile ? (
                      <p
                        className="CustomForm__upload_label"
                        // accept="application/pdf"
                        htmlFor=""
                      >
                        {fileName}
                      </p>
                    ) : (
                      <p className="CustomForm__upload_label" htmlFor="">
                        Upload Portafolio
                      </p>
                    )}

                    <input
                      type="file"
                      size={100}
                      id="inputFile"
                      className="file"
                      onChange={(e) => {
                        validateInputFile(e);
                      }}
                    />
                    <img
                      src={upload}
                      alt="upload"
                      className="profile__icon"
                      id="title"
                    />
                  </div>
                  <div>
                    <input
                      placeholder="Link to profile"
                      type="text"
                      className="CustomForm__input_url"
                      name="link"
                      value={link}
                      onChange={handleInputChange}
                      accept="application/pdf"
                    />
                  </div>
                </div>
                <div className="CustomForm_submit_container_2">
                  {loading && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                        marginRight: "1em",
                        marginTop: "-0.2em",
                        width: "100%",
                      }}
                    >
                      <Spinner />
                    </div>
                  )}
                  {errorApi && (
                    <p className="CustomForm__error">An error was ocurred *</p>
                  )}
                  {errorEmail && (
                    <p className="CustomForm__error">invalid email *</p>
                  )}
                  {error && (
                    <p className="CustomForm__error">
                      submit pdf and/or add link to continue *
                    </p>
                  )}
                  <button
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                    className="CustomForm__form_submit_button"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <div className="CustomForm__form_container">
              <form>
                <div className="CustomForm__form_wrapper">
                  <input
                    className="CustomForm__form_input_text"
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={name}
                    onChange={handleInputChange}
                  />
                  <input
                    type="text"
                    placeholder="Email"
                    className="CustomForm__form_input_text"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="CustomForm__form_select_container">
                  <CustomSelect
                    defaultText="Country"
                    optionsList={countries}
                    selects={selects}
                    setSelects={setSelects}
                    resetDefault={resetDefault}
                    setResetDefault={setResetDefault}
                  />
                  <CustomSelect
                    defaultText="Service"
                    optionsList={services}
                    selects={selects}
                    setSelects={setSelects}
                    resetDefault={resetDefault}
                    setResetDefault={setResetDefault}
                  />
                </div>
                <div>
                  <textarea
                    style={{ resize: "none" }}
                    type="text"
                    placeholder="Message"
                    className="CustomForm__form_input_area"
                    name="message"
                    value={message}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="CustomForm__form_submit_container">
                  {loading && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                        marginRight: "1em",
                        marginTop: "-0.2em",
                        width: "100%",
                      }}
                    >
                      <Spinner />
                    </div>
                  )}
                  {errorApi && (
                    <p className="CustomForm__error">An error was ocurred *</p>
                  )}
                  {errorEmail && (
                    <p className="CustomForm__error">invalid email *</p>
                  )}
                  {error && (
                    <p className="CustomForm__error">
                      please fill in all the fields to continue *
                    </p>
                  )}
                  <button
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                    id="title"
                    className="CustomForm__form_submit_button"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
        <div className="CustomForm__location">
          <Location />
        </div>
      </div>
      {isInfoDate && (
        <div className="CustomForm__info__container">
          <div className="CustomForm_info">
            <InfoDateVertical />
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomForm;
