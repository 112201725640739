import React, { useEffect, useState, Fragment } from "react";
import MainHomeSmall from "./MainHomeSmall";
import MainHome from "./MainHome";
const Index = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  return (
    <Fragment>{windowWidth > 951 ? <MainHome /> : <MainHomeSmall />}</Fragment>
  );
};

export default Index;
