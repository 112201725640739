import React from "react";
import "./Home_2.css";
import textborder from "../../assets/images/border-text.svg";
import boxSticky from "../../assets/images/border-text.svg";
import "./HomeSmall_2.css";

const About = ({ secondRef }) => {
  return (
    <div className="HomeSmall_2__container" ref={secondRef}>
      <div className="HomeSmall_2__wrapper">
        <div style={{ display: "flex" }}>
          <div className="HomeSmall_2__phrase">WE ARE A</div>
          <div className="HomeSmall_2__phrase_box">
            <img src={boxSticky} alt="" />
            CREATIVE
          </div>
        </div>

        <div className="HomeSmall_2__phrase">PARTNER</div>
        <small className="HomeSmall_2__phrase_small">
          We will be your creative partner, together we can close the gap
          between your vision and your audience
        </small>
      </div>
    </div>
  );
};

export default About;
