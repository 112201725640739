import React from "react";
import { Link } from "react-router-dom";
import boxSticky from "../../assets/images/border-text.svg";

import "./HomeSmall_4.css";

const About = ({ fourthRef }) => {
  return (
    <div className="HomeSmall_4__container" ref={fourthRef}>
      <div className="HomeSmall_4__wrapper">
        <div className="HomeSmall_4__box">
          {/* FIRTS PHASE */}
          <div className="HomeSmall_4__phrase">ONE</div>
          <div className="HomeSmall_4__phrase ">
            <img src={boxSticky} alt="" />
            COMPANY
          </div>
          <div className="HomeSmall_4__phrase">TO</div>
        </div>
        {/* SECOND PHRASE */}
        <div className="HomeSmall_4__box">
          <div className="HomeSmall_4__phrase" style={{ width: "100%" }}>
            STRATEGIZE,
          </div>
        </div>
        {/* THIRTH PHRASE */}
        <div className="HomeSmall_4__box">
          <div className="HomeSmall_4__phrase" style={{ width: "100%" }}>
            SUPPORT
          </div>
          <div className="HomeSmall_4__phrase">AND</div>
        </div>
        {/* FORTH PHRASE */}
        <div className="HomeSmall_4__box">
          <div className="HomeSmall_4__phrase">CREATE</div>
          <div className="HomeSmall_4__phrase">YOUR</div>

          <div
            className="HomeSmall_4__phrase"
            style={{
              background: "black",
              color: "white",
              width: "auto",
              padding: "0 0 0 7px",
            }}
          >
            BRAND
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
